import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthService,
  DocumentService,
  Helper,
  IUnidadeGestoraToken,
  MenuItem,
  Module,
  ModulosPublicSoft,
  StatusCertificado,
  ThemeService,
} from '@ps-erp-angular/shared';
import { Optional } from 'ag-grid-community';
import { EventSourcePolyfill } from 'event-source-polyfill';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { PsTabSessionService } from '../ps-tab-session';
import { LancamentosContabeisService } from './../../../../../shared/src/lib/services/lancamentos-contabeis.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-header',
  templateUrl: './ps-header.component.html',
  styleUrls: ['./ps-header.component.scss'],
})
export class PsHeaderComponent implements OnInit {
  selectedValue;
  cpfVisible = false;
  searchVisible = false;
  quickViewVisible = false;
  isFolded: boolean;
  isExpand: boolean;
  @Input() icone: string; //TODO: setar icone padrão;
  @Input() modulo: string;
  @Input() ugExternal: string;
  @Input() brasao: string;
  @Optional()
  @Input()
  urlEventSource!: string;
  moduloIcon = 'assets/images/logo/logo-pen.png';
  publicsoftLogo = 'global-assets/images/logo3.png';
  public name: string;
  public trustedLink;
  brasaoModulo: string;
  selectModURL: string;
  unidadesGestoras: IUnidadeGestoraToken[];
  moduloAbbreviation: string;
  arrayOfExceptions: any = [];
  getNotification = false;
  authorization = false;

  ugSelected: IUnidadeGestoraToken;
  modulosSearch: Module[] = [];
  certificadoLabel: string;
  certificadoInvalido: boolean;
  UG: any;
  UGFarmacia: any;
  moduleIcon: any;
  assNotification: boolean = false;
  dateVisible = false;
  dateMobile = false;

  drawer = false;
  modulosDrawer = false;
  configDrawer = false;
  systemDrawer = false;
  dateModal = false;
  editarPerfil = false;
  href = '';
  percentDone: any;

  constructor(
    private themeService: ThemeService,
    public authService: AuthService,
    private cookieService: CookieService,
    private modal: NzModalService,
    private tabSessionService: PsTabSessionService,
    private router: Router,
    public http: HttpClient,
    public documentService: DocumentService,
    public notification: NzNotificationService,
    public lancamentoContabilService: LancamentosContabeisService
  ) {
    // this.router.events.subscribe(async (event: Event) => {
    //   if (event instanceof NavigationStart) {
    //   }
    //   if (event instanceof NavigationEnd) {
    //     if (
    //       event.url === '/session/dashboard' &&
    //       this.authorization === false
    //     ) {
    //       // if (userInfo.cnpjUg === cnpjSousa && userInfo.idUsuario === 4) {
    //       // }
    //       await this.getDocumentsFromConcentrator();
    //     }
    //     // this.documentService.aClickedEvent.subscribe(async (data) => {
    //     //   if (data) {
    //     //     await this.getDocumentsFromConcentrator();
    //     //   }
    //     //   // if (data && userInfo.cnpjUg === cnpjSousa && userInfo.idUsuario === 4) {
    //     //   // }
    //     // });
    //   }
    // });
    // const subscriptionService = new EventSourcePolyfill(
    //   this.authService.generateUrlByEnvironment(
    //     '3000',
    //     `jobs/events-service-queue`,
    //     'assinatura-documentos'
    //   ),
    //   {
    //     headers: {
    //       Authorization: 'Bearer ' + this.authService.getToken(),
    //     },
    //   }
    // );
    // subscriptionService.onmessage = (message) => {
    //   this.arrayOfExceptions.push({
    //     message: message.data,
    //     data: moment().format('DD/MM - HH:mm:ss'),
    //   });
    // subscriptionService.close();
    // this.getNotification = false;
    // this.documentService.updateDisplay('true');
    // this.documentService.updateDisplayAvulso('true');
    // };
  }
  clearAllNotifications() {
    this.arrayOfExceptions = [];
  }

  async getSelectedUG() {
    let ug;
    await this.authService.getUgSelected().then((result) => {
      ug = result;
    });
    const newUg = ug;
    return newUg;
  }

  ngOnInit(): void {
    if (this.urlEventSource) {
      const subscriptionConsumer = new EventSourcePolyfill(
        this.urlEventSource,
        {
          headers: {
            Authorization: 'Bearer ' + this.authService.getToken(),
          },
        }
      );

      subscriptionConsumer.onmessage = (message) => {
        this.arrayOfExceptions.push({
          message: message.data,
          data: moment().format('DD/MM - HH:mm:ss'),
        });
        // subscriptionConsumer.close();
        this.getNotification = false;
        // this.documentService.updateDisplay('true');
        // this.documentService.updateDisplayAvulso('true');
      };
    }

    this.authService.getModules().then((result) => {
      for (let i = 0; i < result.data.resultado.length; i++) {
        if (result.data.resultado[i].nomModulo === 'Assinatura Digital') {
          this.moduleIcon = result.data.resultado[i].icone;
          this.assNotification = true;
        }
      }
      this.modulosSearch = result.data.resultado;
    });
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
    if (this.UG) {
      this.listCertificadosAssinanteLogado();
    }
    this.authService.subject.subscribe(() => {
      this.unidadesGestoras = this.authService.getUnidadesGestoras();
      this.selectModURL = `${this.authService.getUrlAppAcesso()}/select-module`;
      this.selectUg();
    });

    if (this.authService.getNomUser) {
      this.unidadesGestoras = this.authService.getUnidadesGestoras();
      this.selectModURL = `${this.authService.getUrlAppAcesso()}/select-module`;
      this.selectUg();
    }
    this.getSelectedUG().then((result) => {
      this.UG = result;
    });
    this.nzSelectChange;

    this.authService.getUgInfoBrasao().then((result) => {
      this.UGFarmacia = result;
    });

    this.onValueChange();
  }

  nzSelectChange;
  deleteMsg(msg: string) {
    const index: number = this.arrayOfExceptions.indexOf(msg);
    if (index !== -1) {
      this.arrayOfExceptions.splice(index, 1);
    }

    this.arrayOfExceptions.length <= 0
      ? (this.getNotification = false)
      : (this.getNotification = true);
  }

  async getCertificadosAssinanteLogado() {
    return await this.http
      .post(
        this.authService.generateUrlByEnvironment(
          '5017',
          `api/certificado/by-pessoa`,
          'digitalizacao'
        ),
        {
          pessoa: this.documentService.encryptUsingAES256(
            this.authService.getCpfUser()
          ),
        },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => {
        return result.data;
      });
  }

  listCertificadosAssinanteLogado() {
    this.getCertificadosAssinanteLogado()
      .then((res: any) => {
        if (
          res.find(
            (cert) =>
              cert.status === StatusCertificado.Valido &&
              new Date(cert.dtFinalCert) > new Date()
          )
        ) {
          this.certificadoLabel = 'Certificado Válido';
        }
        if (
          res.every((cert) => new Date(cert.dtFinalCert) < new Date()) &&
          res.length > 0
        ) {
          this.certificadoInvalido = true;
          this.certificadoLabel = 'Certificado Inválido';
          this.notification.warning(
            'Certificado',
            `Renove a validade do seu certificado digital!`,
            { nzDuration: 8000, nzPauseOnHover: true }
          );
          return;
        }

        if (
          res.every((cert) => cert.status === StatusCertificado.Deletado) ||
          res.length === 0
        ) {
          this.certificadoInvalido = true;
          this.certificadoLabel = 'Carregue um Certificado';
          this.notification.info(
            'Certificado',
            `Carregue um certificado digital válido!`,
            { nzDuration: 8000, nzPauseOnHover: true }
          );
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  public headerAuthenticateOptions() {
    return {
      headers: new HttpHeaders({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'request-date': this.authService.getDateSelected(),
      }),
    };
  }

  getIconeModulo() {
    const ug: any = JSON.parse(localStorage.getItem('unidadeGestoraSelected'));
    const modulo = ug.modulos.filter(
      (mod) => mod.codigo === ModulosPublicSoft['Assinatura Digital']
    );
    return (this.brasaoModulo = modulo[0].icone);
  }

  getCpf() {
    return Helper.mascaraCpfCnpj(this.authService.getCpfUser());
  }

  newWindow() {
    window.open('https://www.publicsoft.com.br/');
  }

  getCpfMasked() {
    return Helper.cpfCnpjMascarado(this.authService.getCpfUser());
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  searchToggle(): void {
    this.searchVisible = !this.searchVisible;
  }

  quickViewToggle(): void {
    this.quickViewVisible = !this.quickViewVisible;
  }

  logout(): void {
    this.modal.confirm({
      nzTitle: '<i>Deseja realmente sair do sistema?</i>',
      nzOkText: 'Sim',
      nzCancelText: 'Não',
      nzOnOk: async () => this.authService.logout(),
    });
  }

  // getModuloAbbreviation(): string {
  //   const result = this.ugSelected?.nome.trim().split(' ');
  //   return result[0].substr(0, 1) + result[result.length - 1].substr(0, 1);
  // }

  onValueChange(value: Date = new Date()) {
    this.authService.setDateSelected(value);
    this.dateMobile = false;
    this.dateVisible = false;
    this.dateModal = false;
  }

  onPanelChange(change: { date: Date; mode: string }): void {}

  switchUg(ug) {
    this.modal.confirm({
      nzTitle: '<i>Trocar Unidade Gestora</i>',
      nzContent: '<b>Tem certeza que deseja trocar de Unidade Gestora?</b>',
      nzOnOk: () => this.selectUg(ug),
    });
  }

  getModuloIcon(mod) {
    const icon = this.modulosSearch.filter(
      (modulo) => modulo.codigo === mod.codigo
    );
    for (let i = 0, len = icon.length; i < len; i++) {
      return icon[i].icone ? icon[i].icone : 'https://i.imgur.com/R0wxNKc.png';
    }
  }

  async selectUg(ug = null) {
    const ugSelection = await this.authService.getUgSelected();
    this.ugSelected = ug ? ug : ugSelection;
    this.moduloAbbreviation = null;
    if (ugSelection?.id !== this.ugSelected?.id) {
      this.authService.setUgSelected(this.ugSelected);
      this.UG = await this.documentService.getUnidadeGestoraById(
        this.ugSelected.id
      );
    }
  }

  getUgSelectedModulos() {
    let modulos = [];
    this.ugSelected?.modulos?.map((modulo) => {
      if (modulo.nome === this.modulo) {
        return;
      }

      modulos = modulos.filter((mod) => modulo.codigo !== mod.codigo);
      modulos.push(modulo);
    });

    return modulos;
  }

  addTab(item: MenuItem): void {
    this.tabSessionService.addTab({
      name: item.title,
      url: item.path,
      queryParams: item.queryParams,
    });
    this.router.navigate([item.path], { queryParams: item.queryParams });
  }

  toggleDrawer() {
    this.drawer = !this.drawer;
  }

  toggleModulos() {
    this.modulosDrawer = !this.modulosDrawer;
  }

  toggleConfig() {
    this.configDrawer = !this.configDrawer;
  }

  toggleSystem() {
    this.systemDrawer = !this.systemDrawer;
  }

  toggleDate() {
    this.dateModal = !this.dateModal;
  }

  toggleEditar($event?) {
    $event?.stopPropagation();
    this.editarPerfil = !this.editarPerfil;
  }

  modalChangeUg(ug) {
    this.modal.confirm({
      nzTitle: 'Deseja trocar de Unidade Gestora?',
      nzContent: 'Todo trabalho em progresso não será salvo.',
      nzOkText: 'Sim',
      nzCancelText: 'Não',
      nzOnOk: () => {
        this.selectUg(ug);
      },
    });
  }

  modalChangeModulo(modulo) {
    this.cookieService.set('ps-token', this.authService.getToken(), 1, '/', '');
    const base64Cookie = btoa(JSON.stringify(this.cookieService.getAll()));
    this.modal.confirm({
      nzTitle: 'Deseja trocar de Módulo?',
      nzContent: 'Todo trabalho em progresso não será salvo.',
      nzOkText: 'Sim',
      nzCancelText: 'Não',
      nzOnOk: () => {
        window.open(
          `${modulo.link}?cookie=${encodeURIComponent(base64Cookie)}`,
          '_self'
        );
      },
    });
  }
}
