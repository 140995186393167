import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import { Sistema } from '@ps-erp-angular/shared';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Helper } from '../shared/Helper';
import { AuthService } from '../shared/services/auth.service';
import { ConexoesService } from '../shared/services/conexoes.service';

@Component({
  selector: 'ps-painel-administrativo-app-conexoes',
  templateUrl: './conexoes.component.html',
  styleUrls: ['./conexoes.component.scss'],
})
export class ConexoesComponent implements OnInit {
  loadingPage: boolean = false;
  loadingTable: boolean = false;
  loadingManutencao: boolean = false;
  loadingConsulta: boolean = false;
  tabIndex: 0 | 1 = 1;
  form: FormGroup = new FormGroup({});
  fabButtons: IMenuButton[];
  displayData;
  pageTotal: number;
  checkAllIndeterminate: boolean = true;
  checkedAll = false;
  checkList: { [typekey: string]: boolean } = {
    id: true,
    tenantId: true,
    system: true,
    params: true,
    dtCriacao: true,
  };

  //example, trocar para o nome da entidade arrColumnsExamplesSelect
  tableConexaoColumns = [];
  titulo: string = 'Conexões';
  entidade: string = 'conexao';
  fieldConexoesSelected;
  arrColumnsConexoesSelect: any[];
  showComboTagsConexoes: any[];
  selectedConexoesValues;
  showComboTagsConexao;
  searchInput: any;
  fieldsConexoesSelected = [];
  sistemasEnum = [];

  constructor(
    private service: ConexoesService,
    private activateRouter: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NzModalService
  ) {
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.setConfigConexaoTable();
      await this.setConfigConexaoFilter();
    });

    Object.values(Sistema).forEach((e) => {
      this.sistemasEnum.push({ text: e, value: e });
    });

    this.resetForm();
    this.resetFabButtons();
  }

  async setConfigConexaoFilter() {
    // this.loadingPage = true;

    await this.service.getTableProps().then((result) => {
      Object.assign(this, result);

      this.service.setCompareToTableColumns(
        this.displayData,
        this.tableConexaoColumns
      );
    });
    this.loadingPage = false;
  }

  async setConfigConexaoTable() {
    // this.loadingPage = true;

    await this.service
      .getTableProps(this.entidade, this.titulo)
      .then((result) => {
        const tableColumnsConexoes = result.tableColumnsProps.map((column) => {
          // Mapeamento de larguras
          const widthMap = {
            params: '25em',
            // system: '8rem',
            // dtCriacao: '10rem',
            // id: '5rem',
            // idTenant: '5rem',
          };
          const compareMap = {
            params: (a, b) =>
              JSON.stringify(a[column.value]).localeCompare(
                JSON.stringify(b[column.value])
              ),
            dataCriacao: (a, b) =>
              new Date(a[column.value])
                .toString()
                .localeCompare(new Date(b[column.value]).toString()),
          };

          const defaultCompare = (a, b) =>
            a[column.value].localeCompare(b[column.value]);
          return {
            ...column,
            compare: compareMap[column.value] || defaultCompare,
            width: widthMap[column.value] || null,
          };
        });
        this.tableConexaoColumns = tableColumnsConexoes;

        this.arrColumnsConexoesSelect = result.searchColumns;
      });
    this.loadingPage = false;
  }

  resetForm() {
    //registrar os campos do formulário
    this.form;
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.salvar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.atualizar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  getFilterConexaoOptionsSelect(item) {}

  novoCadastro = () => {
    this.changeTabIndex(0);
  };

  salvar = () => {};

  editar = () => {};

  editarConexao = (item) => {};

  deletar = () => {
    this.removerRegistro(this.getRegistrySelected());
  };

  atualizar = () => {};

  ngOnInit(): void {}

  getWidthContent() {
    return window.innerWidth;
  }

  removerRegistro(registro, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>Deseja realmente remover o registro?</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        await this.service.delete(registro, entidade).then(() => {
          this.removerRegistroGrid(registro);
        });
        this.loadingPage = false;
      },
    });
  }

  removerRegistroGrid(item: any): void {
    // this.displayData.slice(0, this.displayData.indexOf(item))
    this.displayData = this.displayData.filter(
      (data) => data.idPublico !== item.idPublico
    );
  }

  getRegistrySelected() {
    const registrys = this.displayData.filter((registry) => registry.checked);
    if (registrys.length !== 1) {
      this.service.notification.warning(
        this.titulo,
        'Muitos registro selecionados ou nenhum.'
      );
      return;
    }
    const registry = registrys[0];
    return registry;
  }

  check(item) {
    this.checkedAll = this.displayData.every(
      (data: any) => data.checked === true
    );
    this.checkAllIndeterminate = !this.checkedAll;
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  filterConexao() {
    return this.getConexaoPerPeriod(this.fieldsConexoesSelected);
  }

  removeFiltroConexaoPorField(fieldsValuesTag) {
    const tableExportColumn = this.tableConexaoColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsConexoesSelect.splice(
      this.tableConexaoColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsConexoesSelected.splice(
      this.fieldsConexoesSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsConexoesSelected.length === 0) {
      this.showComboTagsConexao = false;
    }
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    if (column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  async getConexaoPerPeriod(fieldsConexaoSelected) {
    this.loadingTable = true;
    await this.service
      .getAllRegistrosInInterval({
        params: fieldsConexaoSelected,
        entidade: this.entidade,
        titulo: this.titulo,
      })
      .then(async (conexao: any) => {
        if (conexao.data.length === 0) {
          this.service.notification.warning(
            this.titulo,
            'Nenhum registro correspondente aos filtros.',
            { nzDuration: 7000 }
          );
        }
        this.pageTotal = conexao.data.paramsPaginator[0];
        this.displayData = conexao.data.resultado;

        this.loadingTable = false;
      })
      .catch((err) => {
        this.service.notification.error(this.titulo, err);
        this.loadingTable = false;
      });
  }

  addFiltroValuesPorCampo() {
    if (this.fieldConexoesSelected && this.searchInput) {
      if (this.fieldConexoesSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldConexoesSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }

      //não permite mais de um filtro
      // if (this.fieldsConexoesSelected.length !== 0) {
      //   this.removeFiltroConexaoPorField(this.fieldsConexoesSelected[0]);
      // }
      this.fieldsConexoesSelected.push({
        props: this.fieldConexoesSelected.value,
        field: this.fieldConexoesSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldConexoesSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsConexoesSelect.findIndex(
        (a) => a.value === this.fieldConexoesSelected.value
      );

      this.arrColumnsConexoesSelect.splice(index, 1);

      this.showComboTagsConexao = true;
      this.fieldConexoesSelected = '';
      this.searchInput = '';
      this.selectedConexoesValues = [];
    }
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;

    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  selectItem(item) {}
}
