import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class PortalServidorService {
  urlGerarLink = environment.linkPortalServidor;
  constructor(private http: HttpClient) {}
  gerarLink({
    cnpj: cnpjInstituicao,
    nomeInstituicao,
    pass,
  }: {
    cnpj: number;
    nomeInstituicao: string;
    pass: string;
  }) {
    return this.http
      .post(
        `${this.urlGerarLink}/api/gerar/token`,
        { cnpjInstituicao, nomeInstituicao },
        { headers: { 'ps-pass': pass } }
      )
      .pipe(debounceTime(2000), distinctUntilChanged());
  }
}
