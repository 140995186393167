import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  PsComponentsModule,
  PsDirectiveModule,
  PsDynamicFormModule,
  PsGridModule,
  PsUiModule,
} from '@ps-erp-angular/ps-ui';
// import { NgChartjsModule } from 'ng-chartjs';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NgxMaskModule } from 'ngx-mask';

import { NzMessageModule } from 'ng-zorro-antd/message';
import { ApiComponent } from './api/api.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CidadeComponent } from './cidade/cidade.component';
import { ClienteComponent } from './cliente/cliente.component';
import { EditarPerfilComponent } from './editar-perfil/editar-perfil.component';
import { InstituicaoSiconfiComponent } from './instituicao-siconfi/instituicao-siconfi.component';
import { LayoutComponent } from './layout/layout.component';
import { LicencaComponent } from './licenca/licenca.component';
import { ModuloComponent } from './modulo/modulo.component';
import { OrgaoComponent } from './orgao/orgao.component';
import { ParceiroComponent } from './parceiro/parceiro.component';
import { PerfilComponent } from './perfil/perfil.component';
import { PessoaFisicaComponent } from './pessoa-fisica/pessoa-fisica.component';
import { PessoaJuridicaComponent } from './pessoa-juridica/pessoa-juridica.component';
import { BrasilApiService } from './portal-servidor/brasil-api.service';
import { PortalServidorComponent } from './portal-servidor/portal-servidor.component';
import { PortalServidorService } from './portal-servidor/portal-servidor.service';
import { ProcessoComponent } from './processo/processo.component';
import { ProvedoresComponent } from './provedores/provedores.component';
import { ServidorCloudComponent } from './servidor-cloud/servidor-cloud.component';
import { GrupoNaturezaJuridicaService } from './shared/services/grupo-natureza-juridica.service';
import { OrgaoService } from './shared/services/orgao.service';
import { PessoaFisicaService } from './shared/services/pessoa-fisica.service';
import { PessoaJuridicaService } from './shared/services/pessoa-juridica.service';
import { SubmoduloComponent } from './submodulo/submodulo.component';
import { UnidadeGestoraComponent } from './unidade-gestora/unidade-gestora.component';
import { UnidadeOrcamentariaComponent } from './unidade-orcamentaria/unidade-orcamentaria.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { ConexoesComponent } from './conexoes/conexoes.component';
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    LayoutComponent,
    ProcessoComponent,
    CidadeComponent,
    PessoaFisicaComponent,
    PessoaJuridicaComponent,
    OrgaoComponent,
    InstituicaoSiconfiComponent,
    UnidadeGestoraComponent,
    UnidadeOrcamentariaComponent,
    LicencaComponent,
    PerfilComponent,
    UsuarioComponent,
    ClienteComponent,
    ParceiroComponent,
    SubmoduloComponent,
    ModuloComponent,
    ApiComponent,
    ProvedoresComponent,
    EditarPerfilComponent,
    ServidorCloudComponent,
    PortalServidorComponent,
    ConexoesComponent,
  ],
  imports: [
    NzMessageModule,
    NzTabsModule,
    NzTreeModule,
    NzModalModule,
    NzRadioModule,
    NzUploadModule,
    NzDividerModule,
    NzTagModule,
    NzIconModule,
    NzInputModule,
    NzCardModule,
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    // NgChartjsModule,
    NgxMaskModule,
    AppRoutingModule,
    PsDynamicFormModule.forRoot({
      validationMessages: [{ name: 'pattern', message: 'Formato invalido' }],
    }),
    PsGridModule.forRoot({ gridType: 'antd' }),
    PsUiModule.forRoot({ appName: 'Siafic', appVersion: '1.0.0' }),
    PsComponentsModule,
    PsDirectiveModule,
    NzCollapseModule,
    NzTableModule,
    NzSpinModule,
    NzPaginationModule,
    NzDropDownModule,
    NzButtonModule,
    NzGridModule,
    NzFormModule,
    NzSelectModule,
    NzCascaderModule,
    NzCheckboxModule,
    NzAvatarModule,
    NzDatePickerModule,
    NzBadgeModule,
    NzToolTipModule,
  ],
  providers: [
    { provide: NzModalRef, useValue: {} },
    CidadeComponent,
    PessoaFisicaService,
    GrupoNaturezaJuridicaService,
    PessoaJuridicaService,
    OrgaoService,
    BrasilApiService,
    PortalServidorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
