export const environment = {
  production: false,
  ambiente: 'HOMOLOGACAO',
  linkPortalServidor: 'https://ps-portal-servidor-api.dev.publicsoft.com.br',
  url: (service) => `https://ps-${service}.dev.publicservice.com.br/api`,
  panelUrl: () =>
    `https://ps-painel-administrativo-app.dev.publicservice.com.br`,
  apiOld: (service) => `https://ps-${service}-api.dev.publicservice.com.br`,
  apiAssinatura: (path) =>
    `https://ps-assinatura-documentos-api.dev.publicservice.com.br/${path}`,
  apiUrl: (service) => `https://ps-${service}-api.dev.publicservice.com.br`,
  apiFarmacia: (path) =>
    `https://ps-transparencia-farmacia-basica-api.dev.publicservice.com.br/${path}`,
  apiTools: (service) => `https://ps-${service}-api.api.publicservice.com.br`,
  appUrl: (service) => `https://ps-${service}-app.dev.publicservice.com.br`,
  urlAcesso: `https://ps-acesso-app.dev.publicservice.com.br`,
  domain: 'dev.publicservice.com.br',
  domainSoft: 'publicsoft.com.br',
};
