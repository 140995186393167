import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  IUnidadeGestoraToken,
  Module,
  ModulosPublicSoft,
} from '@ps-erp-angular/shared';
import { Buffer } from 'buffer';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class AuthService {
  @Output() aClickedEvent = new EventEmitter<string>();

  private http: HttpClient;
  public authServiceUrl = this.generateUrlByEnvironment('5001', 'api', 'auth');
  private token: string;
  private tokenDinamico: string;
  protected usuarioAvatar: string;
  protected usuarioNome: string;
  protected usuarioEmail: string;
  protected usuarioCpf: string;
  protected unidadeGestoras: any;
  protected unidadeGestoraSelected: any;
  protected unidadeGestoraFarmacia: any;
  protected submodulos = [];
  protected formularios = [];
  protected dateSelected: Date = new Date();
  public externalDate: Date;
  private helper = new JwtHelperService();
  public subject: Subject<{
    autenticado: boolean;
    token?: string;
    subject?: any;
  }>;
  public urlServer: {};
  public dateSelectedSubject: Subject<string>;

  constructor(
    http: HttpClient,
    private router: Router,
    private cookierService: CookieService,
    private modal: NzModalService,
    public notification: NzNotificationService
  ) {
    this.urlServer = {
      [`concentrador`]: this.generateUrlByEnvironment(
        '5020',
        'api/v1/solicitacoes',
        'concentrador'
      ),
      [`auth`]: this.generateUrlByEnvironment('5001', 'api', 'auth'),
    };
    this.http = http;
    this.subject = new Subject();
    this.dateSelectedSubject = new Subject();
  }

  generateUrlByEnvironment(port: string, path?: string, server = ''): string {
    // if (server === 'assinatura-worker') {
    //   return `http://localhost:3001/${path}`;
    // }

    // if (server === 'exportador') {
    //   return `http://localhost:3030/${path}`;
    // }

    // if (server === 'exportador') {
    //   return `http://localhost:3030/${path}`;
    // }

    // if (['digitalizacao'].includes(server)) {
    //   return `http://localhost:3334/api/${path}`;
    // }

    // if (server === 'assinatura-documentos') {
    //   return `http://localhost:3000/${path}`;
    // }

    if (server === 'transparencia-farmacia-basica') {
      return `${environment.apiFarmacia(path)}`;
    }
    return `${environment.apiUrl(server)}/${path}`;
  }

  public headerAuthenticateOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getToken()}`,
      }),
    };
  }

  getUrlAppAcesso(): string {
    return `${environment.urlAcesso}`;
  }

  clickedEvent(msg: any) {
    this.aClickedEvent.emit(msg);
  }

  public getOrgaoSelected() {
    if (localStorage && this.isAuthenticated()) {
      this.unidadeGestoraSelected = JSON.parse(
        localStorage.getItem('unidadeGestoraSelected')
      );
      return this.unidadeGestoraSelected;
    }
  }

  public autenticarMod(
    idUnidadeGestora = 1,
    codModulo: number = 3
  ): Observable<{
    autenticado: boolean;
    token?: string;
    subject?: any;
  }> {
    const data = {
      codModulo,
      idUnidadeGestora,
    };
    this.http
      .post(this.authServiceUrl + '/loginModulo', data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        }),
      })
      .subscribe(
        async (response: any) => {
          if (response) {
            if (localStorage) {
              let formularios = [];
              const idSubmodulos = [];
              const submodulos = response.submodulos.filter((sub) => {
                if (!idSubmodulos.includes(sub.id)) {
                  idSubmodulos.push(sub.id);
                  return true;
                }
              });
              formularios = response.formularios.filter((item) => {
                return !formularios.includes(item);
              });
              localStorage.setItem('token', response.accessToken.accessToken);
              localStorage.setItem(
                'usuarioIdModulo',
                JSON.stringify(data.codModulo)
              );
              localStorage.setItem('submodulos', JSON.stringify(submodulos));
              localStorage.setItem('formularios', JSON.stringify(formularios));
              localStorage.setItem(
                'usuarioEmail',
                this.cookierService.get('user-email')
              );
              localStorage.setItem(
                'usuarioNome',
                this.cookierService.get('user-nome')
              );
              localStorage.setItem(
                'usuarioCpf',
                this.cookierService.get('user-cpf')
              );
              localStorage.setItem(
                'unidadeGestoraSelected',
                this.cookierService.get('user-ug')
              );
              localStorage.setItem(
                'unidadesGestoras',
                this.cookierService.get('user-ugs')
              );
              localStorage.setItem(
                'primeiro-acesso',
                this.cookierService.get('primeiro-acesso')
              );
              this.unidadeGestoras = this.cookierService.get('user-ugs');
              this.unidadeGestoraSelected = this.cookierService.get('user-ug');
              this.submodulos = submodulos;
              this.formularios = formularios;
            }
            this.token = response.accessToken.accessToken;
            this.sendNext();
          }
        },
        (err) => {
          this.logout();
          this.sendNext(err);
        }
      );

    return this.subject;
  }

  async sendDocumentToConcentrator(processo) {
    return await this.http
      .post(
        this.generateUrlByEnvironment(
          '5017',
          `document/concentrador?assinar=false`,
          'assinatura-documentos'
        ),
        { processo },
        {
          reportProgress: true,
          observe: 'events',
          headers: new HttpHeaders({
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'request-date': this.getDateSelected(),
          }),
        }
      )
      .toPromise()
      .then((response: any) => {
        return response.body.result;
      });
  }

  async sendNotifications(
    psHash,
    msg: string,
    msgMotivo?: string,
    signatario?: any,
    tipoDocumento: { codTipoDocumento; tipo } = {
      codTipoDocumento: '',
      tipo: '',
    }
  ) {
    await this.http
      .get(
        `${environment.apiUrl(
          'concentrador'
        )}/api/v1/solicitacoes/hash/${psHash}`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(async (resultado: any) => {
        const form = {
          codModulo: ModulosPublicSoft.Contabilidade,
          codStatus: 1,
          cnpjParceiro: resultado.data.cnpjParceiro,
          cnpjUg: resultado.data.cnpjUg,
          hashProcessoParceiro: psHash,
          numeroProcessoParceiro: resultado.data.numeroProcessoParceiro,
          codForm: resultado.data.codForm,
          codSubmodulo: 3,
          processo: {
            mensagem: msg,
            motivo: msgMotivo ?? '',
            assinante: signatario ?? '',
            tipoDocumento,
          },
        };
        await this.sendSolicitationToConcentrator(form);
      })
      .catch((err) => {});
  }

  async sendSolicitationToConcentrator(solicitacao) {
    await this.http
      .post(
        `${environment.apiUrl('concentrador')}/api/v1/solicitacoes/create`,
        { ...solicitacao },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(async (resultado: any) => {
        return resultado.data;
      })
      .catch((err) => {
        throw new Error('Erro no envio da solicitação.' + err);
      });
  }

  public async logout() {
    const domain = environment.domain;

    const cookies = this.cookierService.getAll();
    for (const cookie in cookies) {
      if (cookies.hasOwnProperty(cookie)) {
        this.cookierService.delete(cookie, '/', domain);
        this.cookierService.delete(cookie, '/');
        this.cookierService.delete(cookie);
      }
    }

    if (this.getToken()) {
      await this.http
        .post(this.authServiceUrl + '/logout', null, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getToken()}`,
          }),
        })
        .toPromise()
        .then(() => {
          this.token = null;
          localStorage.clear();

          // this.cookierService.deleteAll();
          window.location.href = this.getUrlAppAcesso();
          return;
        });
    }

    this.token = null;
    localStorage.clear();
    // this.cookierService.deleteAll();

    window.location.href = this.getUrlAppAcesso();
  }

  private sendNext(error?) {
    const nextValue = {
      autenticado: this.isAuthenticated(),
      token: this.token,
      subject: this.getIdUser(),
      err: error,
    };
    this.subject.next(nextValue);
    this.subject.complete();
  }

  public getAvatar() {
    if (localStorage && this.isAuthenticated()) {
      return this.cookierService.get('user-avatar');
    }
  }

  public getAvatarUUID() {
    if (localStorage && this.isAuthenticated()) {
      return localStorage.getItem('avatarUUID');
    }
  }

  public isAuthenticated(): boolean {
    const t = localStorage.getItem('token');
    if (!t || !this.helper.decodeToken(t)) {
      return false;
    }
    return !this.helper.isTokenExpired(t);
  }

  public getToken() {
    if (localStorage) {
      this.token = localStorage.getItem('token');
    }
    if (this.isAuthenticated()) {
      return this.token;
    }
  }

  private hasToken() {
    return !!this.token;
  }

  public getTokenDinamico() {
    if (localStorage) {
      this.tokenDinamico = localStorage.getItem('tokenDinamico');
    }

    if (this.isAuthenticated()) {
      return this.tokenDinamico;
    }
  }

  public getIdUser() {
    if (localStorage && this.isAuthenticated()) {
      return this.helper.decodeToken(this.getToken()).userInfo.idUsuario;
    }
  }

  public getUserInfo(tokenDinamico = false) {
    if (localStorage && this.isAuthenticated()) {
      return this.helper.decodeToken(
        tokenDinamico ? this.getTokenDinamico() : this.getToken()
      ).userInfo;
    }
  }

  public getNomUser() {
    if (localStorage && this.isAuthenticated()) {
      this.usuarioNome = localStorage.getItem('usuarioNome');
      return this.usuarioNome;
    }
  }

  public getEmailUser() {
    if (localStorage && this.isAuthenticated()) {
      this.usuarioEmail = localStorage.getItem('usuarioEmail');
      return this.usuarioEmail;
    }
  }

  public getCpfUser() {
    if (localStorage && this.isAuthenticated()) {
      this.usuarioCpf = localStorage.getItem('usuarioCpf');
      return this.usuarioCpf;
    }
  }

  async getUgSelected(): Promise<IUnidadeGestoraToken> {
    if (localStorage && this.isAuthenticated()) {
      this.unidadeGestoraSelected = JSON.parse(
        localStorage.getItem('unidadeGestoraSelected')
      );
      return this.unidadeGestoraSelected;
    }
  }

  async getUgInfoBrasao(): Promise<any> {
    if (localStorage) {
      this.unidadeGestoraFarmacia = JSON.parse(localStorage.getItem('info-ug'));
      return this.unidadeGestoraFarmacia;
    }
  }

  public getAceiteTermoAdesao() {
    if (localStorage && this.isAuthenticated()) {
      return this.http.get(
        this.generateUrlByEnvironment(
          '5017',
          'assinante/termo-aceite/check',
          'assinatura-documentos'
        ),
        this.headerAuthenticateOptions()
      );
    }
  }

  getModules(): Promise<any> {
    return this.http
      .get(
        `${this.generateUrlByEnvironment(
          '5010',
          'api',
          'autorizacao'
        )}/modulo/1/pesquisar-avancado?page=1&size=10`
      )
      .toPromise();
  }

  public getBrasaoUgSelected() {
    return '';
    // tslint:disable-next-line: max-line-length
    // return 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Bras%C3%A3o_de_Campina_Grande_-_PB.svg/200px-Bras%C3%A3o_de_Campina_Grande_-_PB.svg.png';
  }

  public getUnidadesGestoras(): IUnidadeGestoraToken[] {
    if (localStorage && this.isAuthenticated()) {
      this.unidadeGestoras = JSON.parse(
        localStorage.getItem('unidadesGestoras')
      );
      return this.unidadeGestoras;
    }
  }

  public getFormularios() {
    if (localStorage) {
      this.formularios = JSON.parse(localStorage.getItem('formularios'));
      return this.formularios;
    }
  }

  public getSubmodulos() {
    if (localStorage) {
      this.submodulos = JSON.parse(localStorage.getItem('submodulos'));
      return this.submodulos;
    }
  }

  public async setUgSelected(ug) {
    if (ug) {
      this.cookierService.set(
        'user-ug',
        JSON.stringify(ug),
        1,
        '/',
        environment.domain
      );
      await this.autenticarMod(ug.id).toPromise();
      window.location.reload();
    }

    if (localStorage && this.isAuthenticated()) {
      localStorage.setItem('unidadeGestoraSelected', JSON.stringify(ug));
    }
  }

  getDateSelectedWithoutTime() {
    const ano = new Date().getFullYear();
    const mes = new Date().getMonth();
    const dia = new Date().getDate();
    let today = moment(new Date(ano, mes, dia).getTime()).format();
    today = today.substr(0, today.lastIndexOf('-'));

    return today;
  }

  getDateSelected() {
    // this.dateSelected.setHours(new Date().getHours());
    // this.dateSelected.setMinutes(new Date().getMinutes());
    // this.dateSelected.setSeconds(new Date().getSeconds());
    const dateSelected = moment(new Date()).format();
    // dateSelected = dateSelected.substr(0, dateSelected.lastIndexOf('-'));
    return dateSelected;
  }

  setDateSelected(date) {
    this.dateSelected = date;
    this.externalDate = date;
    this.dateSelectedSubject.next(this.getDateSelected());
  }

  externalizeDate() {
    return this.externalDate;
  }

  getDomainUrl() {
    return (environment as any).ambiente === 'DEVELOPMENT' ||
      (environment as any).ambiente === 'LOCALHOST'
      ? 'localhost'
      : 'publicsoft.com.br';
  }

  // DEPRECIADO
  isAdmUser() {
    return this.submodulos.some((submodulo) => submodulo.adm === 1);
  }

  convertToBase64(file): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve((reader.result as string).split(',')[1]); // Remove o prefixo "data:*/*;base64,"
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    localStorage.clear();
    const domain = environment.domain;
    const domainSoft = environment.domainSoft;
    if (route.queryParams.c) {
      const conexaoAtiva = JSON.parse(
        atob(decodeURIComponent(route.queryParams['c']))
      );
      localStorage.setItem('conexao-ativa', conexaoAtiva);
      const brasaoUG = await this.http
        .get(
          this.generateUrlByEnvironment(
            '80',
            `api/farmacia/configuracao/brasao/${conexaoAtiva}`,
            'transparencia-farmacia-basica'
          ),
          this.headerAuthenticateOptions()
        )
        .toPromise()
        .then(async (result: { data: any; message: string }) => {
          const byteArray = new Uint8Array(result.data[0].brasao.length);
          for (let i = 0; i < result.data[0].brasao.length; i++) {
            byteArray[i] = result.data[0].brasao.charCodeAt(i);
          }

          const binary = String.fromCharCode.apply(null, byteArray);
          const base64String = btoa(binary);
          return base64String;
        });

      const infoUG = await this.http
        .get(
          this.generateUrlByEnvironment(
            '80',
            `api/farmacia/instituicao/${conexaoAtiva}`,
            'transparencia-farmacia-basica'
          ),
          this.headerAuthenticateOptions()
        )
        .toPromise()
        .then((result: { data: any; message: string }) => {
          return result.data[0];
        });

      const infoUgBrasao = {
        nome: infoUG.nomeinstituicaoIns,
        nomCidade: infoUG.nomecidadeCi,
        estadoCidade: infoUG.ufCi,
        brasao: `data:image/png;base64,${brasaoUG}`,
      };

      localStorage.setItem('info-ug', JSON.stringify(infoUgBrasao));
      this.cookierService.set(
        'info-ug',
        JSON.stringify(infoUgBrasao),
        1,
        '/',
        domainSoft
      );

      this.cookierService.set(
        'conexao-ativa',
        conexaoAtiva,
        1,
        '/',
        domainSoft
      );

      return true;
    }
    if (route.queryParams.cookie) {
      const token = await this.http
        .get(`${this.authServiceUrl}/token?uuid=${route.queryParams['cookie']}`)
        .toPromise()
        .catch((error) => {
          this.notification.error('Token', error);
          this.logout();
          return false;
        });
      if (!token) {
        this.notification.error('Token', 'Sessão não encontrada!');
        this.logout();
        return false;
      }
      const cookies = JSON.parse(atob(decodeURIComponent(token['data'])));
      this.cookierService.set('ps-token', cookies['ps-token'], 1, '/', domain);
      this.cookierService.set(
        'user-avatar',
        cookies['user-avatar'],
        1,
        '/',
        domain
      );
      this.cookierService.set(
        'modulo-id',
        cookies['modulo-id'],
        1,
        '/',
        domain
      );
      this.cookierService.set(
        'modulo-codigo',
        cookies['modulo-codigo'],
        1,
        '/',
        domain
      );
      this.cookierService.set('user-cpf', cookies['user-cpf'], 1, '/', domain);
      this.cookierService.set(
        'user-email',
        cookies['user-email'],
        1,
        '/',
        domain
      );
      this.cookierService.set('user-id', cookies['user-id'], 1, '/', domain);
      this.cookierService.set(
        'user-nome',
        cookies['user-nome'],
        1,
        '/',
        domain
      );
      this.cookierService.set('user-ug', cookies['user-ug'], 1, '/', domain);
      this.cookierService.set('user-ugs', cookies['user-ugs'], 1, '/', domain);
    }
    if (
      !this.hasToken() ||
      !this.cookierService.get('ps-token') ||
      this.helper.decodeToken(this.getToken()).userInfo.idUsuario !==
        this.helper.decodeToken(this.cookierService.get('ps-token')).userInfo
          .idUsuario
    ) {
      if (this.cookierService.get('ps-token')) {
        this.token = this.cookierService.get('ps-token');
        localStorage.setItem('token', this.token);
        localStorage.setItem(
          'modulo-icone',
          this.cookierService.get('modulo-icone')
        );
        localStorage.setItem(
          'modulo-nome',
          this.cookierService.get('modulo-nome')
        );
        const ugSelected = JSON.parse(this.cookierService.get('user-ug'));
        const moduloSelect = JSON.parse(
          this.cookierService.get('modulo-codigo')
        );
        return this.autenticarMod(ugSelected.id, moduloSelect)
          .toPromise()
          .then(() => {
            // await this.getConcentratorNotification();

            return true;
          })
          .catch((e) => {
            this.logout();
            return false;
          });
      }

      if (!this.cookierService.get('ps-token')) {
        this.logout();
        return false;
      }
    }
    return true;
  }

  hexToBase64(hex: string): string {
    const bytes: Buffer = Buffer.from(hex, 'hex');
    const base64 = bytes.toString('base64');
    return base64;
  }

  public getmoduloNomeIcone(): Partial<Module> {
    return {
      nomModulo: localStorage.getItem('modulo-nome'),
      icone: localStorage.getItem('modulo-icone'),
    };
  }

  createModalTermoAceite(querys?) {
    // let loadingButton = false;
    //TODO: Verificar uso
    // return this.modal.create({
    //   nzTitle: 'Termos de adesão',
    //   nzWidth: 1050,
    //   nzContent: ModalTermoAceiteComponent,
    //   nzOnOk: () => new Promise((resolve) => setTimeout(resolve, 1000)),
    //   nzFooter: [
    //     {
    //       label: 'recusar',
    //       onClick: () =>
    //         (window.location.href = `${this.getUrlAppAcesso()}/select-module`),
    //     },
    //     {
    //       loading: loadingButton,
    //       label: 'Salvar',
    //       disabled: (componentInstance) =>
    //         this.disableButton(componentInstance),
    //       type: 'primary',
    //       onClick: (componentInstance) => {
    //         loadingButton = true;
    //         return componentInstance
    //           .assinarTermo(this.getUgSelected(), querys)
    //           .then(() => (loadingButton = false))
    //           .catch(() => (loadingButton = false));
    //       },
    //     },
    //   ],
    // });
  }

  disableButton(component) {
    if (component.formB.invalid) {
      return true;
    }
    if (component.formB.value.isCheckedAceiteTermos === false) {
      return true;
    }
    return false;
  }
}
