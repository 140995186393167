import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
environment;

@Injectable()
export class BrasilApiService {
  constructor(private http: HttpClient) {}

  consultarCnpj(cnpj: number) {
    return this.http
      .get(`https://brasilapi.com.br/api/cnpj/v1/${cnpj}`)
      .pipe(debounceTime(2000), distinctUntilChanged());
  }
}
