<nz-spin [nzSpinning]="loadingPage" nzSize="large">
  <ps-complex-form [title]="'Portal do Servidor'" [disabledTable]="true" [tabIndex]="tabIndex" [isAntd]="true"
    (changeTabIndex)="changeTabIndex($event)">
    <div body>
      <nz-spin [nzSpinning]="loadingManutencao">
        <form nz-form [formGroup]="form" nzLayout="vertical">
          <h4 class="m-t-15 m-l-20">Gerar link</h4>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <div nz-row [nzGutter]="16">
            <div nz-col nzSpan="5" nzSm="5" nzXs="24">
              <nz-form-item>
                <nz-form-label>CNPJ Instituição</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="cnpj" placeholder="Digite um CNPJ Válido"
                    (ngModelChange)="changeIndexCNPJ($event)" mask="00.000.000/0000-00">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="9" nzSm="9" nzXs="24">
              <nz-form-item>
                <nz-form-label>Nome da Instituição</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="nomeInstituicao" placeholder="Digite o CNPJ primeiro...">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="5" nzSm="5" nzXs="24">
              <nz-form-item nzTooltipTitle="Mesma senha master usada para acessar o Portal do Servidor"
                nzTooltipPlacement="top" nz-tooltip>
                <nz-form-label>Chave Mestra</nz-form-label>
                <nz-form-control>
                  <nz-input-group [nzSuffix]="suffixTemplate">
                    <input [type]="passwordVisible ? 'text' : 'password'" [(ngModel)]="password" nz-input
                      formControlName="chaveMestre" placeholder="Senha Portal Servidor" />
                  </nz-input-group>
                  <ng-template #suffixTemplate>
                    <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                      (click)="passwordVisible = !passwordVisible"></i>
                  </ng-template>
                  <!-- <input nz-input formControlName="chaveMestre" placeholder="Digite o CNPJ primeiro..."> -->
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="3" nzSm="3" nzXs="24">
              <nz-form-item>
                <nz-form-label>&nbsp;</nz-form-label>
                <nz-form-control>
                  <button nz-button nzType="primary" style="width: 100%;" (click)="gerarLink()">Gerar link</button>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="2" nzSm="2" nzXs="24">
              <nz-form-item>
                <nz-form-label>&nbsp;</nz-form-label>
                <nz-form-control>
                  <button nz-button nzType="primary" style="width: 100%;" (click)="formReset()" nzDanger><i nz-icon
                      nzType="clear" nzTheme="outline"></i></button>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <nz-spin [nzSpinning]="formLoading">
            <div nz-row [nzGutter]="16">
              <div nz-col nzSpan="24" nzSm="24" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Link</nz-form-label>
                  <nz-form-control (click)="copyToClipboard()"
                    [nzTooltipTitle]="this.form.controls.link.value?'Click para copiar link!':''"
                    nzTooltipPlacement="top" nz-tooltip>
                    <nz-input-group style="width: 100%;" [nzSuffix]="copyTemplate">
                      <textarea rows="4" nz-input formControlName="link" style="width: 100%;"
                        placeholder="......"></textarea>
                    </nz-input-group>
                    <ng-template #copyTemplate>
                      <span nz-icon class="ant-input-password-icon" [nzType]="'copy'"></span>
                    </ng-template>
                    <!-- <input nz-input formControlName="link" style="width: 100%;" placeholder="......"> -->
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </nz-spin>
        </form>
      </nz-spin>
    </div>
    <div table>
    </div>
  </ps-complex-form>
</nz-spin>