<nz-spin [nzSpinning]="loadingPage" nzSize="large">
  <ps-complex-form [title]="titulo" [tabIndex]="tabIndex" [isAntd]="true" (changeTabIndex)="changeTabIndex($event)">
    <div body #formContainer>
      <nz-spin [nzSpinning]="loadingManutencao">
        <form nz-form [formGroup]="form" nzLayout="vertical">
          <h4 class="m-t-15 m-l-20">Informações básicas</h4>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <div nz-row class="p-l-20 p-r-20" [nzGutter]="22">
            <div nz-col nzSpan="5" nzSm="5" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>CPF</nz-form-label>
                <nz-form-control nzErrorTip="Código é obrigatório."
                  [nzValidateStatus]="getValidateStatus('cpfPessoaFisica')">
                  <nz-input-group nzSearch [nzAddOnAfter]="cpfPessoaFisicaLupa">
                    <input formControlName="cpfPessoaFisica" [mask]="'000.000.000-00'"
                      (keydown)="showModalPessoaFisica()" nz-input placeholder="CPF"
                      (click)="showModalPessoaFisica()" />
                  </nz-input-group>
                </nz-form-control>
                <ng-template #cpfPessoaFisicaLupa>
                  <button *ngIf="pessoaFisica" (click)="limparPessoaFisica()" nz-button type="button" nzType="text"
                    nzSearch>
                    <i nz-icon nzType="close-circle"></i>
                  </button>
                  <button nz-button nzType="primary" (click)="showModalPessoaFisica()" nzSearch><span nz-icon
                      nzType="search"></span></button>
                </ng-template>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8" nzSm="8" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Nome</nz-form-label>
                <nz-form-control>
                  <input nz-input placeholder="Nome" formControlName="nomePessoaFisica">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="7" nzSm="7" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Email</nz-form-label>
                <nz-form-control>
                  <input nz-input placeholder="Email" formControlName="emailPessoaFisica">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="4" nzSm="4" nzXs="24">
              <nz-form-label>
              </nz-form-label>
              <nz-form-control>
                <label nz-checkbox status="primary" [(ngModel)]="usuarioAtivo" formControlName="ativo">Usuário
                  ativo</label>
              </nz-form-control>
            </div>
          </div>

          <nz-divider class="m-t-0 m-b-15" nzOrientation="left"></nz-divider>
          <nz-tabset nzType="card" [nzTabBarExtraContent]="extraTemplate">
            <nz-tab nzTitle="Vinculações">
              <div formArrayName="vinculacoes">
                <div *ngFor="let vinculacao of form.get('vinculacoes')['controls']; index as i" [formGroupName]="i">
                  <nz-collapse nzAccordion>
                    <nz-collapse-panel [nzHeader]="getHeaderName(vinculacao.value)"
                      [nzActive]="vinculacao.value.activeCollapse">
                      <!-- <div nz-row [nzGutter]="[32,32]" class="p-l-10 p-r-10"> -->

                      <!-- <fieldset nz-col nzSpan="23" nzSm="23" nzXs="24">
                        <div> -->
                      <div nz-row [nzGutter]="24">
                        <div nz-col nzSpan="6" nzSm="6" nzXs="24">
                          <nz-form-item>
                            <nz-form-label nzRequired>Unidade gestora</nz-form-label>
                            <nz-form-control>
                              <nz-input-group nzSearch [nzAddOnAfter]="unidadeGestora">
                                <input formControlName="desUnidadeGestora" (keydown)="showModalUg(i)" nz-input
                                  placeholder="Descrição da unidade gestora" (click)="showModalUg(i)" />
                              </nz-input-group>
                            </nz-form-control>
                            <ng-template #unidadeGestora>
                              <button *ngIf="vinculacao.value.perfis" (click)="limparUg(i)" nz-button type="button"
                                nzType="text" nzSearch>
                                <i nz-icon nzType="close-circle"></i>
                              </button>
                              <button nz-button nzType="primary" (click)="showModalUg(i)" nzSearch><span nz-icon
                                  nzType="search"></span></button>
                            </ng-template>
                          </nz-form-item>

                        </div>

                        <div nz-col nzSpan="5" nzSm="6" nzXs="24">
                          <nz-form-item>
                            <nz-form-label nzRequired>Perfil</nz-form-label>
                            <nz-form-control>
                              <nz-form-control>
                                <nz-select (ngModelChange)="selectOption($event, vinculacao.value.perfis, i)" fullWidth
                                  nzPlaceHolder="Perfil" formControlName="idPerfil">
                                  <nz-option *ngFor="let perfil of vinculacao.value.perfis; index as i"
                                    [nzLabel]="perfil.nomPerfil" [nzValue]="perfil.id"></nz-option>

                                </nz-select>
                              </nz-form-control>
                            </nz-form-control>
                          </nz-form-item>
                        </div>

                        <div nz-col nzSpan="4" nzSm="4" nzXs="24">
                          <nz-form-label nzRequired>
                            Validade acesso
                          </nz-form-label>
                          <nz-form-control>
                            <nz-select fullWidth nzPlaceHolder="Validade acesso" formControlName="validadeAcesso">
                              <nz-option nzLabel="7 dias" [nzValue]=7></nz-option>
                              <nz-option nzLabel="15 dias" [nzValue]=15></nz-option>
                              <nz-option nzLabel="30 dias" [nzValue]=30></nz-option>
                              <nz-option nzLabel="90 dias" [nzValue]=90></nz-option>
                              <nz-option nzLabel="120 dias" [nzValue]=120></nz-option>
                              <nz-option nzLabel="180 dias" [nzValue]=180></nz-option>
                              <nz-option nzLabel="365 dias" [nzValue]=365></nz-option>
                              <nz-option nzLabel="Indeterminado" [nzValue]=999999></nz-option>
                            </nz-select>
                          </nz-form-control>
                        </div>

                        <div nz-col nzSpan="5" nzSm="5" nzXs="24">
                          <nz-form-label>
                          </nz-form-label>
                          <nz-form-control>
                            <label nz-checkbox status="primary" [(ngModel)]="usuarioVinculadoAtivo[i]"
                              formControlName="ativo">Vinculação ativa</label>
                          </nz-form-control>
                        </div>

                        <div nz-col nzSpan="3" nzSm="3" nzXs="24">
                          <button *ngIf="form.value.vinculacoes.length > 1" class="m-t-20" nz-button nzDanger
                            [disabled]="false" nzType="primary" (click)="removeVinculacao(i)">
                            <i nz-icon nzType="delete"></i>
                          </button>
                        </div>
                      </div>

                      <nz-tabset nzType="card">
                        <div *ngIf="legacyProfile">
                          <nz-tab *ngFor="let modulo of getHeaderTabName(vinculacao.value.perfil)" nz-icon
                            [nzType]="'lock'" [nzTitle]="titleTemplate">
                            <ng-template #titleTemplate>
                              {{modulo.modulo.nomModulo}}
                            </ng-template>
                            <div formArrayName="vinculacoesModuloLegado">
                              <div
                                *ngFor="let vinculacaoModuloLegado of vinculacao.get('vinculacoesModuloLegado').controls; let legadoIndex=index"
                                [formGroupName]="legadoIndex">

                                <div
                                  *ngIf="vinculacaoModuloLegado.value.idModulo === modulo.modulo.id && legacyProfile">
                                  <div nz-row [nzGutter]="22" form>
                                    <div nz-col nzSpan="8" nzSm="8" nzXs="24">
                                      <nz-form-item>
                                        <nz-form-label>Nome do Usuário (Módulo)</nz-form-label>
                                        <nz-form-control>
                                          <input nz-input placeholder="Nome" formControlName="nomeUsuarioModulo">
                                        </nz-form-control>
                                      </nz-form-item>
                                    </div>
                                    <div nz-col nzSpan="7" nzSm="7" nzXs="24">
                                      <nz-form-label>Senha do Usuário (Módulo)</nz-form-label>
                                      <nz-form-item class="mb-0">
                                        <nz-form-control nzErrorTip="Por favor insira sua senha">
                                          <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                                            <input [type]="passwordVisible ? 'text' : 'password'" nz-input
                                              formControlName="senhaUsuarioModulo"
                                              placeholder="Senha do Usuário (Módulo)" />
                                          </nz-input-group>
                                        </nz-form-control>
                                        <ng-template #suffixTemplate>
                                          <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                                            (click)="passwordVisible = !passwordVisible"></i>
                                        </ng-template>

                                      </nz-form-item>
                                    </div>
                                    <div nz-col nzSpan="4" nzSm="4" nzXs="24">
                                      <nz-form-label>
                                      </nz-form-label>
                                      <nz-form-control>
                                        <label nz-checkbox status="primary" (change)="onCheckboxChange(legadoIndex)"
                                          formControlName="splash">Apresentar
                                          Splash</label>
                                      </nz-form-control>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </nz-tab>

                        </div>

                      </nz-tabset>

                    </nz-collapse-panel>
                  </nz-collapse>
                </div>

              </div>
            </nz-tab>
            <div *ngIf="legacyProfile">
              <nz-tab nzTitle="Configurações Diversas">
                <div style="margin: 15px;margin-left: 0px;" nz-row class="p-l-20 p-r-20" [nzGutter]="22">

                  <div nz-col nzSpan="8" nzSm="8" nzXs="24">
                    <nz-form-item>
                      <nz-form-label>Nome do Usuário (Servidor Cloud)</nz-form-label>
                      <nz-form-control>
                        <input nz-input placeholder="Nome" formControlName="nomeUsuarioServidor">
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col nzSpan="7" nzSm="7" nzXs="24">
                    <nz-form-label>Senha do Usuário (Servidor Cloud)</nz-form-label>
                    <nz-form-item class="mb-0">
                      <nz-form-control nzErrorTip="Por favor insira sua senha">
                        <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                          <input [type]="passwordVisible ? 'text' : 'password'" nz-input
                            formControlName="senhaUsuarioServidor" placeholder="Senha do Usuário (Servidor)" />
                        </nz-input-group>
                      </nz-form-control>
                      <ng-template #suffixTemplate>
                        <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                          (click)="passwordVisible = !passwordVisible"></i>
                      </ng-template>
                    </nz-form-item>
                  </div>
                </div>
              </nz-tab>
            </div>
          </nz-tabset>
          <ng-template #extraTemplate>
            <button nz-button [disabled]="!verificaVinculacaoVazia(form.value.vinculacoes)" nzType="primary"
              (click)="addVinculacao()">
              <i nz-icon nzType="plus"></i>
            </button>
          </ng-template>


        </form>
      </nz-spin>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
    <div table>
      <div class="row is-not-mobile">
        <div class="col-md-24 col-xl-12 p-b-15">
          <nz-collapse nzGhost>
            <nz-collapse-panel [nzHeader]="'Filtros'" [nzActive]="true">
              <div nz-row class="d-flex">
                <div nz-col nzSpan="24" nzXs="24">
                  <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                    <div class="p-r-10">
                      <div class="p-l-10" nz-col nzSpan="24">
                        <div nz-row nzGutter="16">
                          <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                            <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo"
                              [(ngModel)]="fieldUsuariosSelected"
                              (ngModelChange)="getFilterUsuarioOptionsSelect(fieldUsuariosSelected)">
                              <nz-option *ngFor="let interval of arrColumnsUsuariosSelect" [nzValue]="interval"
                                [nzLabel]="interval?.text">
                              </nz-option>
                            </nz-select>
                          </div>

                          <div nz-col [nzOffset]="this.openModal?2:0" nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                            <nz-input-group *ngIf="fieldUsuariosSelected && fieldUsuariosSelected.type === 'input'"
                              [nzPrefix]="''">
                              <input type="text" nz-input class="text-disabled" placeholder="Informe um valor"
                                [(ngModel)]="searchInput" />
                            </nz-input-group>
                          </div>
                          <div
                            *ngIf="fieldUsuariosSelected && tabIndex == 1 && fieldUsuariosSelected.type === 'range-picker'"
                            nzSpan="8" nzXl="8" nzLg="8" nzXs="24" class="m-l-10">
                            <nz-range-picker nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-range-picker>
                          </div>
                          <div nz-col *ngIf="fieldUsuariosSelected" nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
                            <button nz-button [disabled]="
                                !searchInput
                              " (click)="addFiltroValuesPorCampo()" nzType="primary">
                              <i nz-icon nzType="plus"></i>
                            </button>
                          </div>

                        </div>
                        <div nz-row class="m-t-10">
                          <div *ngIf="showComboTagsUsuarios" class="p-0" nz-col nzSpan="12" nzXl="12" nzLg="12"
                            nzXs="24">
                            <nz-input-group [nzPrefix]="' '">
                              <div class="align-self-center">
                                <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'" (nzOnClose)="
                            removeFiltroUsuarioPorField(fieldsValuesTag)
                            " class="filters" *ngFor="let fieldsValuesTag of fieldsUsuariosSelected; let i = index ">
                                  {{ fieldsValuesTag.text }}
                                </nz-tag>
                              </div>
                            </nz-input-group>
                          </div>
                          <div nz-col [class]="'m-t-10 p-l-20'" [nzOffset]="showComboTagsUsuarios?8:20" nzSpan="3"
                            nzXl="3" nzLg="3" nzXs="24">

                            <button class="m-l-10" nzSize="small" nz-button nzType="primary" (click)="filterUsuario()">
                              <i nz-icon nzType="filter" nzTheme="outline"></i>Aplicar Filtro
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
      <nz-table nzSize="small" [nzLoading]="loadingTable" [nzShowPagination]="true" #UsuarioListTable
        [nzScroll]="{ y: '420px' }" style="overflow-y: overlay" [nzData]="displayData">
        <thead>
          <tr>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " [nzLeft]="true" nzWidth="15px"></th>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " nzWidth="60px" [nzLeft]="true"><label nz-checkbox
                [nzIndeterminate]="checkAllIndeterminate" (ngModelChange)="updateAllChecked()"
                [(ngModel)]="checkedAll"></label>
            </th>
            <ng-container *ngFor="let column of tableUsuarioColumns">
              <th class="p-t-15 p-b-15" nzWidth="150px" *ngIf="checkList[column.value]" nzWidth="{{column.width}}"
                [nzSortFn]="column.compare">
                {{ column.text }} </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-item [ngForOf]="UsuarioListTable.data" let-index="index">
            <tr (click)="selectItem(item)" (dblclick)="doubleClick(item)" style="cursor: pointer"
              nzTooltipPlacement="left">
              <td [nzLeft]="getWidthContent() > 1024"></td>
              <td [nzLeft]="getWidthContent() > 1024">
                <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="check(item)"></label>
              </td>
              <ng-container *ngFor="let column of tableUsuarioColumns">
                <td *ngIf="checkList[column.value]">
                  {{ prepareColumnValue(item, column) }}
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
  </ps-complex-form>
</nz-spin>