<ng-template #cardTitle>

  <h3 *ngIf="title && !titleTemplate">{{ title }}</h3>
  <div *ngIf="!title"><ng-container *ngTemplateOutlet="titleTemplate"></ng-container></div>

</ng-template>

<nz-card style="width: 100%" [nzTitle]="cardTitle" [nzExtra]="extra">
  <nz-tabset [nzSelectedIndex]="tabIndex" [nzAnimated]="false" class="nz-tabset__container">
    <nz-tab>
      <ng-content select="[body]"></ng-content>
    </nz-tab>
    <nz-tab>
      <ng-content *ngIf="isAntd; else agGrid" select="[table]"></ng-content>
      <ng-template #agGrid>
        <ps-consulta [filterFields]="filterFields" [filterFieldsAvancado]="filterFieldsAvancado"
          [defaultColDefs]="defaultColDefs" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
          [rowData]="rowData"></ps-consulta>
      </ng-template>
    </nz-tab>
  </nz-tabset>
</nz-card>

<ng-template #extra>
  <div class="d-flex justify-content-between">
    <nz-radio-group class="is-not-mobile" [(ngModel)]="tabIndex" nzButtonStyle="solid"
      (ngModelChange)="changeModel($event)">
      <label nz-radio-button [nzValue]="0" [nzDisabled]="disabledBody">Manutenção</label>
      <label nz-radio-button [nzValue]="1" [nzDisabled]="disabledTable">Consulta</label>
    </nz-radio-group>

    <div class="is-mobile">
      <button nz-button nzType="default" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
        <i nz-icon nzType="more"></i>
      </button>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item (click)="tabIndex = 0">Manutenção</li>
          <li nz-menu-item (click)="tabIndex = 1">Consulta</li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>
</ng-template>