import { Component } from '@angular/core';
import {
  AuthService,
  DocumentsUploadService,
  MenuItem,
  MenuService,
} from '@ps-erp-angular/shared';

@Component({
  selector: 'ps-painel-administrativo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  formLoading = false;
  labelTip: string;
  formulario = [];

  menu: MenuItem[] = [
    {
      path: '/session/dashboard',
      title: 'Dashboard',
      entidade: 'dashboard',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'area-chart',
      submenu: [],
      initialPage: true,
    },
    {
      path: '/session/cadastros-basicos',
      title: 'Cadastros Básicos',
      entidade: '',
      icon: 'container',
      iconTheme: 'outline',
      iconType: 'nzIcon',
      submenu: [
        {
          path: '/session/cadastros-basicos/cidade',
          title: 'Cidades',
          entidade: 'cidade',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'environment',
          submenu: [],
        },
      ],
    },
    {
      path: '/session/pessoa',
      title: 'Pessoa',
      entidade: '',
      icon: 'user',
      iconTheme: 'outline',
      iconType: 'nzIcon',
      submenu: [
        {
          path: '/session/pessoa/pessoa-fisica',
          title: 'Pessoa Física',
          entidade: '',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'user',
          submenu: [],
        },
        {
          path: '/session/pessoa/pessoa-juridica',
          title: 'Pessoa Jurídica',
          entidade: '',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'team',
          submenu: [],
        },
      ],
    },
    {
      path: '/session/orgaos',
      title: 'Orgãos',
      entidade: '',
      icon: 'appstore',
      iconTheme: 'outline',
      iconType: 'nzIcon',
      submenu: [
        {
          path: '/session/orgaos/unidade-gestora',
          title: 'Unidade Gestora',
          entidade: '',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: '',
          submenu: [],
        },
        {
          path: '/session/orgaos/orgao',
          title: 'Orgão',
          entidade: '',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: '',
          submenu: [],
        },
        {
          path: '/session/orgaos/unidade-orcamentaria',
          title: 'Unidade Orçamentaria',
          entidade: '',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: '',
          submenu: [],
        },
        {
          path: '/session/orgaos/instituicao-siconfi',
          title: 'Instituição Siconfi',
          entidade: '',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: '',
          submenu: [],
        },
      ],
    },
    {
      path: '/session/permissoes',
      title: 'Permissões',
      entidade: 'permissoes',
      icon: 'lock',
      iconTheme: 'outline',
      iconType: 'nzIcon',
      submenu: [
        {
          path: '/session/permissoes/licenca',
          title: 'Licença',
          entidade: 'licenca',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: '',
          submenu: [],
        },
        {
          path: '/session/permissoes/perfil',
          title: 'Perfil',
          entidade: 'perfil',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: '',
          submenu: [],
        },
        {
          path: '/session/permissoes/usuario',
          title: 'Usuário',
          entidade: 'usuario',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: '',
          submenu: [],
        },
        // {
        //   path: '/session/permissoes/cliente',
        //   title: 'Cliente',
        //   entidade: 'cliente',
        //   iconType: 'nzIcon',
        //   iconTheme: 'outline',
        //   icon: '',
        //   submenu: [],
        // },
        // {
        //   path: '/session/permissoes/parceiro',
        //   title: 'Parceiro',
        //   entidade: 'parceiro',
        //   iconType: 'nzIcon',
        //   iconTheme: 'outline',
        //   icon: '',
        //   submenu: [],
        // },
      ],
    },
    {
      path: '/session/sistemas',
      title: 'Sistemas',
      entidade: '',
      icon: 'setting',
      iconTheme: 'outline',
      iconType: 'nzIcon',
      submenu: [
        {
          path: '/session/sistemas/servidor-cloud',
          title: 'Servidor Cloud',
          entidade: 'servidorCloud',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'cloud-server',
          submenu: [],
        },
        {
          path: '/session/sistemas/modulo',
          title: 'Modulos',
          entidade: 'modulo',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'cluster',
          submenu: [],
        },
        {
          path: '/session/sistemas/submodulo',
          title: 'Submodulos',
          entidade: 'submodulo',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'cluster',
          submenu: [],
        },
      ],
    },
    {
      path: '/session/controle',
      title: 'Controle',
      entidade: '',
      icon: 'setting',
      iconTheme: 'outline',
      iconType: 'nzIcon',
      submenu: [
        {
          path: '/session/controle/apis',
          title: 'Apis',
          entidade: 'api',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'api',
          submenu: [],
        },
        {
          path: '/session/controle/conexoes',
          title: 'Conexões',
          entidade: 'api',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'api',
          submenu: [],
        },
      ],
    },
    {
      path: '/session/portais',
      title: 'Portais',
      entidade: '',
      icon: 'hdd',
      iconTheme: 'outline',
      iconType: 'nzIcon',
      submenu: [
        {
          path: '/session/portais/servidor',
          title: 'Servidor',
          entidade: 'servidor',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'profile',
          submenu: [],
        },
      ],
    },
  ];

  constructor(
    private menuService: MenuService,
    private authService: AuthService,
    private service: DocumentsUploadService
  ) {
    this.menuService.menuItems.next(this.menu);
    this.formulario = this.authService.getFormularios();

    this.service.formLoading.subscribe(async (data) => {
      this.formLoading = data;
    });

    this.service.labelTip.subscribe(async (msg) => {
      this.labelTip = msg;
    });
  }

  mountMenu() {
    let data;

    this.formulario.forEach((form) => {
      data = this.menu.map((menu) => {
        menu.submenu.map((submenu) => {
          if (submenu.entidade === form.entidade) {
            return submenu;
          }
        });

        return menu;
      });
    });

    this.menuService.menuItems.next(data);
  }
}
