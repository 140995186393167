import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BrasilApiService } from './brasil-api.service';
import { PortalServidorService } from './portal-servidor.service';

@Component({
  selector: 'ps-portal-servidor-app',
  templateUrl: './portal-servidor.component.html',
  styleUrls: ['./portal-servidor.component.scss'],
})
export class PortalServidorComponent implements OnInit {
  loadingPage: boolean = false;
  loadingTable: boolean = false;
  loadingManutencao: boolean = false;
  loadingConsulta: boolean = false;
  tabIndex: 0 | 1 = 0;
  fabButtons;
  formLoading = false;

  passwordVisible = false;
  password?: string;

  constructor(
    private notificationService: NzNotificationService,
    private message: NzMessageService,
    private fb: FormBuilder,
    private brasilApiService: BrasilApiService,
    private portalServidorService: PortalServidorService
  ) {}
  form: FormGroup = new FormGroup({});
  ngOnInit(): void {
    this.form = this.fb.group({
      cnpj: [null, [Validators.required]],
      nomeInstituicao: [{ value: '', disabled: true }, [Validators.required]],
      link: [{ value: '', disabled: true }],
      chaveMestre: [null, Validators.required],
    });
  }

  async changeIndexCNPJ(cnpj) {
    this.form.controls.nomeInstituicao.reset();
    this.form.controls.link.reset();
    this.form.controls.nomeInstituicao.disable();
    if (String(cnpj).length === 14) {
      this.form.controls.link.reset();
      const sub = this.brasilApiService.consultarCnpj(cnpj).subscribe({
        next: (data: any) => {
          this.form.controls.nomeInstituicao.enable();

          this.form.controls.nomeInstituicao.setValue(
            this.capitalizeString(data.nome_fantasia)
          );
        },
        error: (error) => {
          this.notificationService.error(
            'Consultar CNPJ',
            `CNPJ não encontrado, verificar se foi digitado corretamente. Error:${error}`
          );
        },
        complete: () => {
          sub.unsubscribe();
        },
      });
    }
  }

  capitalizeString(input: string): string {
    return input
      .toLowerCase() // Transforma tudo para minúsculas
      .split(' ') // Divide a string em palavras
      .map(
        (word) =>
          word.length > 2 // Capitaliza apenas palavras com mais de 2 letras
            ? word.charAt(0).toUpperCase() + word.slice(1)
            : word // Mantém palavras curtas como estão (ex.: "de", "da")
      )
      .join(' '); // Junta as palavras novamente em uma string
  }

  formReset() {
    this.form.reset();
    this.form.controls.link.disable();
  }
  // Exemplo de uso:
  // const original = "PREFEITURA MUNICIPAL DE SANTA INES";
  // const formatted = capitalizeString(original);
  // console.log(formatted); // "Prefeitura Municipal de Santa Ines"

  changeTabIndex(c) {}

  copyToClipboard(): void {
    if (this.form.controls.link.value) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(this.form.controls.link.value)
          .then(() => {
            this.message.create('success', `Link copiado com sucesso!`);
          })
          .catch((err) => {
            this.message.create('error', 'Erro ao copiar texto: ', err);
          });
      } else {
        // Abordagem alternativa para navegadores que não suportam `navigator.clipboard`
        const textarea = document.createElement('textarea');
        textarea.value = this.form.controls.link.value;
        textarea.style.position = 'fixed'; // Evita que a página role
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
          document.execCommand('copy');
          this.message.create('success', `Link copiado com sucesso!`);
        } catch (err) {
          this.message.create('error', 'Erro ao copiar texto: ', err);
        }
        document.body.removeChild(textarea);
      }
    }
  }
  async gerarLink() {
    if (!this.form.valid) {
      this.notificationService.warning(
        'Formulário',
        'Preencha todos os campos corretamente!'
      );
      return;
    }
    if (this.form.controls.cnpj.value.length !== 14) {
      this.notificationService.warning('Formulário', 'CNPJ Inválido!');
      return;
    }
    this.formLoading = true;
    const sub = this.portalServidorService
      .gerarLink({
        cnpj: this.form.controls.cnpj.value,
        nomeInstituicao: this.form.controls.nomeInstituicao.value,
        pass: this.form.controls.chaveMestre.value,
      })
      .subscribe({
        next: (resp: any) => {
          this.form.controls.link.setValue(resp.data);
          this.form.controls.link.enable();
        },
        error: (error) => {
          if (String(error).includes('401')) {
            this.notificationService.error(
              'Erro ao Gerar link',
              `Senha Incorreta. Erro: ${error}`
            );
          }
          this.form.controls.link.reset();
        },
        complete: () => {
          sub.unsubscribe();
          this.formLoading = false;
        },
      });
  }
}
