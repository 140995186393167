import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import { Helper } from '@ps-erp-angular/shared';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { IUsuario } from '../shared/interfaces/usuario.interface';
import { AuthService } from '../shared/services/auth.service';
import { UsuarioService } from '../shared/services/usuario.service';
import { PessoaFisicaComponent } from './../pessoa-fisica/pessoa-fisica.component';
import { UnidadeGestoraComponent } from './../unidade-gestora/unidade-gestora.component';

@Component({
  selector: 'ps-painel-administrativo-app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss'],
})
export class UsuarioComponent implements AfterViewInit, AfterViewChecked {
  @ViewChild('formContainer') formContainer: ElementRef;
  private shouldScrollToLastForm = false;

  passwordVisible = false;
  openModal: boolean = false;
  loadingPage: boolean = false;
  selectedOption: boolean = false;
  loadingTable: boolean = false;
  loadingManutencao: boolean = false;
  loadingConsulta: boolean = false;
  legacyProfile: boolean = false;
  tabIndex: 0 | 1 = 1;
  form: FormGroup = new FormGroup({});
  fabButtons: IMenuButton[];
  displayData;
  pageTotal: number;
  checkAllIndeterminate: boolean = true;
  checkedAll = false;
  checkList: { [typekey: string]: boolean } = {
    pessoaFisicaNome: true,
    pessoaFisicaCpf: true,
  };
  pessoaFisica: any;
  intervaloDigitandoCpf: any;
  validateStatus: { [typekey: string]: () => 'sucess' | 'error' } = {};

  perfil;
  usuarioAtivo: boolean = true;
  apresentarSplash: boolean = false;
  usuarioVinculadoAtivo: boolean[] = [];

  tableUsuarioColumns = [];
  titulo: string = 'Usuário';
  entidade: string = 'usuario';
  fieldUsuariosSelected;
  arrColumnsUsuariosSelect: any[];
  showComboTagsExemplos: any[];
  selectedUsuariosValues;
  showComboTagsUsuarios;
  searchInput: any;
  fieldsUsuariosSelected = [];
  modulos = [];
  tabs = [
    {
      id: 0,
      name: 'Vinculações',
    },
    {
      id: 1,
      name: 'Configurações Diversas',
    },
  ];
  constructor(
    private service: UsuarioService,
    private activateRouter: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NzModalService,
    private cdRef: ChangeDetectorRef
  ) {
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.setConfigUsuarioTable();
      await this.setConfigUsuarioFilter();
    });
    this.resetForm();
    this.resetFabButtons();
  }

  async setConfigUsuarioFilter() {
    this.loadingPage = true;

    await this.service.getTableProps().then((result) => {
      Object.assign(this, result);

      this.service.setCompareToTableColumns(
        this.displayData,
        this.tableUsuarioColumns
      );
    });
    this.loadingPage = false;
  }

  getValidateStatus(validator: string): 'sucess' | 'error' {
    return this.validateStatus[validator]();
  }

  async setConfigUsuarioTable() {
    this.loadingPage = true;

    await this.service
      .getTableProps(this.entidade, this.titulo)
      .then((result) => {
        const tableColumnsUsuarios = result.tableColumnsProps.map((column) => {
          return {
            ...column,
            compare:
              column.value === 'id' || column.value === 'id_uf'
                ? (a, b) => a[column.value] - b[column.value]
                : (a, b) => a[column.value].localeCompare(b[column.value]),
          };
        });
        this.tableUsuarioColumns = tableColumnsUsuarios;

        this.arrColumnsUsuariosSelect = result.searchColumns;
      });
    this.loadingPage = false;
  }

  async consultaCpf(cpf) {
    if (cpf.length < 11) {
      return;
    }
    this.loadingPage = true;
    await this.service
      .consultaPessoaFisica(cpf)
      .then((result) => {
        this.loadingPage = false;
        this.pessoaFisica = result.data;
        this.form.patchValue({
          idPessoaFisica: this.pessoaFisica.id,
          nomePessoaFisica: this.pessoaFisica.pessoa.nomPessoa,
          // nomeUsuarioServidor: this.pessoaFisica.pessoa.nomPessoa,
          // senhaUsuarioServidor: this.pessoaFisica.pessoa.nomPessoa,
          emailPessoaFisica: this.pessoaFisica.pessoa.emailPrincipal,
          ativo: this.usuarioAtivo,
        });
      })
      .catch((err) => {
        this.loadingPage = false;
        this.pessoaFisica = err.data;
        this.service.notificarErro(err, this.titulo);

        this.form.patchValue({
          idPessoaFisica: null,
          nomePessoaFisica: null,
          emailPessoaFisica: null,
          ativo: false,
        });
      });
  }

  resetForm() {
    this.form = this.formBuilder.group({
      id: [null],
      idPessoaFisica: [null, Validators.required],
      cpfPessoaFisica: [null, Validators.required],
      nomePessoaFisica: [{ value: null, disabled: true }],
      nomeUsuarioServidor: [null],
      senhaUsuarioServidor: [null],
      emailPessoaFisica: [{ value: null, disabled: true }],
      ativo: [{ value: this.usuarioAtivo }],
      senha: [null],
      aplicacaoOrigem: [1],
      usuarioCriacao: [this.authService.getIdUser()],
      dtCriacao: [new Date()],
      vinculacoes: this.formBuilder.array([
        this.formBuilder.group({
          id: [null],
          validadeAcesso: [null, Validators.required],
          idHorario: [null],
          unidadeGestora: [null, Validators.required],
          desUnidadeGestora: [null],
          ugNaoEncontrada: [false],
          intervaloDigitandoUg: [null],
          messageUgNaoEncontrada: [null],
          perfil: [null],
          ativo: [{ default: true }],
          idPerfil: [null],
          indexVinculacao: [null],
          indexVinculacaoLegado: [null],
          activeCollapse: [true],
          perfis: [null],
          horarios: [null],
          aplicacaoOrigem: [1],
          usuarioCriacao: [this.authService.getIdUser()],
          vinculacoesModuloLegado: this.formBuilder.array([]),
        }),
      ]),
    });

    this.setValidateStatus(['idPessoaFisica', 'cpfPessoaFisica']);
  }

  formVincModuloLegado(idModulo?: number) {
    return this.formBuilder.group({
      idPrivado: [null],
      nomeUsuarioModulo: [null],
      senhaUsuarioModulo: [null],
      splash: [null],
      idUnidadeGestora: [null],
      idMunicipio: [null],
      idModulo: [idModulo ?? null],
    });
  }

  setValidateStatus(formControlNameArr: Array<string>) {
    for (const formControlName of formControlNameArr) {
      this.validateStatus[formControlName] = (): 'sucess' | 'error' =>
        this.form.get(formControlName).errors &&
        (this.form.get(formControlName).touched ||
          this.form.get(formControlName).dirty)
          ? 'error'
          : 'sucess';
    }
  }

  showModalPessoaFisica() {
    const tipoDocumentoModal = this.modalService.create({
      nzTitle: `Selecione um responsável `,
      nzContent: PessoaFisicaComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    tipoDocumentoModal.afterClose.subscribe((pessoaFisica) => {
      this.pessoaFisica = pessoaFisica;
      const contatoPrincipal = pessoaFisica.contatos.find(
        (endereco) => endereco.principal === 1
      );
      this.form.patchValue({
        idPessoaFisica: pessoaFisica.id,
        cpfPessoaFisica: pessoaFisica.cpf,
        nomePessoaFisica: pessoaFisica.nomPessoa,
        emailPessoaFisica: contatoPrincipal.email,
        ativo: this.usuarioAtivo,
      });
    });
  }
  limparPessoaFisica() {
    this.form.patchValue({
      cpfPessoaFisica: null,
      idPessoaFisica: null,
      nomePessoaFisica: null,
      emailPessoaFisica: null,
    });
    this.pessoaFisica = null;
  }

  showModalUg(index) {
    const tipoDocumentoModal = this.modalService.create({
      nzTitle: `Selecione uma Unidade Gestora `,
      nzContent: UnidadeGestoraComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    tipoDocumentoModal.afterClose.subscribe(async (unidadeGestora) => {
      this.loadingPage = true;
      if (unidadeGestora) {
        const perfis = await this.service.consultaPerfil(
          unidadeGestora.id,
          1,
          100
        );
        this.loadingPage = false;

        this.form.get(`vinculacoes.${index}`).patchValue({
          unidadeGestora: unidadeGestora,
          desUnidadeGestora: unidadeGestora.razaoSocialPessoaJuridica,
          perfis: perfis,
        });

        const perfilSelected = perfis.filter(
          (itm) => itm.idUnidadeGestora === unidadeGestora.id
        );

        this.form.get(`vinculacoes.${index}.perfis`).setValue(perfilSelected);
      }
      this.loadingPage = false;
    });
    this.loadingPage = false;
  }

  limparUg(index) {
    this.form.get(`vinculacoes.${index}`).patchValue({
      unidadeGestora: null,
      perfil: null,
      desUnidadeGestora: null,
      idPerfil: null,
      perfis: null,
      validadeAcesso: null,
    });
    this.form.get(`vinculacoes.${index}.unidadeGestora`).setValue(null);
  }

  removeVinculacao(index: number) {
    if (this.form.get(`vinculacoes.${index}.id`).value) {
      return this.modalService.confirm({
        nzTitle: `<i>Deseja remover vinculação?</i>`,
        nzOnOk: async () => {
          this.loadingPage = true;
          await this.service
            .excluirFilho(
              this.form.value.id,
              this.form.get(`vinculacoes.${index}.id`).value,
              'vinculacao',
              this.entidade,
              this.titulo
            )
            .then(() => {
              this.loadingPage = false;
              const vinculacaoFormb: FormArray = this.form.get(
                'vinculacoes'
              ) as FormArray;

              this.form.get(`vinculacoes.${index}.indexVinculacao`).reset();
              vinculacaoFormb.removeAt(index);
            })
            .catch((err) => {
              this.loadingPage = false;
              this.service.notificarErro(err, this.titulo);
            });
        },
        nzOnCancel: () => {
          this.loadingPage = false;
        },
      });
    }
    const vinculacaoFormb: FormArray = this.form.get(
      'vinculacoes'
    ) as FormArray;
    vinculacaoFormb.removeAt(index);
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.salvar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: this.tabIndex === 0,
        onClick: this.consultar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.atualizar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  consultar = () => {
    this.changeTabIndex(1);
  };

  getFilterUsuarioOptionsSelect(item) {}

  novoCadastro = () => {
    this.resetForm();
    this.changeTabIndex(0);
  };

  salvar = async () => {
    await this.onSubmit();
  };

  editar = async () => {
    await this.prepareToUpdateForm(this.getRegistrySelected());
  };

  doubleClick = (item) => {
    this.openModal ? null : this.editarUsuario(item);
  };

  editarUsuario = async (item) => {
    await this.prepareToUpdateForm(item);
  };

  deletar = () => {
    this.removerRegistro(this.getRegistrySelected());
  };

  atualizar = () => {
    this.filterUsuario();
  };

  ngAfterViewInit() {
    this.scrollToLastForm();
  }

  ngAfterViewChecked() {
    if (this.shouldScrollToLastForm) {
      this.scrollToLastForm();
      this.shouldScrollToLastForm = false;
    }
  }

  getWidthContent() {
    return window.innerWidth;
  }

  removerRegistro(registro, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>Deseja realmente remover o registro?</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        await this.service.delete(registro, entidade, this.titulo).then(() => {
          this.removerRegistroGrid(registro);
        });
        this.loadingPage = false;
      },
    });
  }

  removerRegistroGrid(item: any): void {
    this.displayData = this.displayData.filter(
      (data) => data.idPublico !== item.idPublico
    );
  }

  getRegistrySelected() {
    const registrys = this.displayData.filter((registry) => registry.checked);
    if (registrys.length !== 1) {
      this.service.notification.warning(
        this.titulo,
        'Muitos registro selecionados ou nenhum.'
      );
      return;
    }
    const registry = registrys[0];
    return registry;
  }

  scrollToLastForm() {
    if (this.formContainer && this.formContainer.nativeElement) {
      this.formContainer.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }

  onCheckboxChange(indexLegacy: number) {
    // console.log(`LegacyCheckbox at index ${index} changed.`);
  }

  async prepareToUpdateForm(usuario) {
    this.form.get('id').setValue(usuario.id);
    this.form.get('cpfPessoaFisica').setValue(usuario.pessoaFisicaCpf);
    this.form.get('senha').setValue(usuario.senha);
    this.form.get('usuarioCriacao').setValue(usuario.usuarioCriacao);
    this.form.get('aplicacaoOrigem').setValue(usuario.aplicacaoOrigem);
    this.form.get('nomeUsuarioServidor').setValue(usuario.nomeUsuarioServidor);
    this.form
      .get('senhaUsuarioServidor')
      .setValue(
        usuario.senhaUsuarioServidor
          ? await this.service.decryptData(usuario.senhaUsuarioServidor)
          : null
      );
    this.form.get('dtCriacao').setValue(usuario.dtCriacao);
    this.usuarioAtivo = !!usuario.ativo;

    for (const index in usuario.vinculacoes) {
      if (usuario.vinculacoes[index]) {
        if (!this.form.get(`vinculacoes.${index}`)) {
          this.addVinculacao();
        }
        if (usuario.vinculacoes[index].vinculacoesModuloLegado.length > 0) {
          for (const indexLegado in usuario.vinculacoes[index]
            .vinculacoesModuloLegado) {
            const vinculacoesForm: FormArray = this.form.get(
              'vinculacoes'
            ) as FormArray;
            const formGroup = vinculacoesForm.at(Number(index)) as FormGroup;
            const vinculacoesLegadoForm: FormArray = formGroup.get(
              'vinculacoesModuloLegado'
            ) as FormArray;
            vinculacoesLegadoForm.push(
              this.formBuilder.group({
                idPrivado: [
                  usuario.vinculacoes[index].vinculacoesModuloLegado[
                    indexLegado
                  ].idPrivado,
                ],
                nomeUsuarioModulo: [
                  usuario.vinculacoes[index].vinculacoesModuloLegado[
                    indexLegado
                  ].nomeUsuarioModulo,
                ],
                senhaUsuarioModulo: [
                  usuario.vinculacoes[index].vinculacoesModuloLegado[
                    indexLegado
                  ].senhaUsuarioModulo
                    ? await this.service.decryptData(
                        usuario.vinculacoes[index].vinculacoesModuloLegado[
                          indexLegado
                        ].senhaUsuarioModulo
                      )
                    : null,
                ],
                splash: [
                  usuario.vinculacoes[index].vinculacoesModuloLegado[
                    indexLegado
                  ].apresentarSplash === 'S'
                    ? true
                    : false,
                ],
                idUnidadeGestora: [
                  usuario.vinculacoes[index].unidadeGestora.id,
                ],
                idMunicipio: [
                  usuario.vinculacoes[index].unidadeGestora.idMunicipio,
                ],
                idModulo: [
                  usuario.vinculacoes[index].vinculacoesModuloLegado[
                    indexLegado
                  ].idModulo,
                ],
              })
            );

            // this.form
            //   .get(
            //     `vinculacoes.${index}.vinculacoesModuloLegado.${indexLegado}.idPrivado`
            //   )
            //   .setValue(
            //     usuario.vinculacoes[index].vinculacoesModuloLegado[indexLegado]
            //       .idPrivado
            //   );

            // this.form
            //   .get(
            //     `vinculacoes.${index}.vinculacoesModuloLegado.${indexLegado}.nomeUsuarioModulo`
            //   )
            //   .setValue(
            //     usuario.vinculacoes[index].vinculacoesModuloLegado[indexLegado]
            //       .nomeUsuarioModulo
            //   );

            // this.form
            //   .get(
            //     `vinculacoes.${index}.vinculacoesModuloLegado.${indexLegado}.splash`
            //   )
            //   .setValue(
            //     usuario.vinculacoes[index].vinculacoesModuloLegado[indexLegado]
            //       .apresentarSplash === 'S'
            //       ? true
            //       : false
            //   );
            // this.apresentarSplash =
            //   usuario.vinculacoes[index].vinculacoesModuloLegado[indexLegado]
            //     .apresentarSplash === 'S'
            //     ? true
            //     : false;

            // this.form
            //   .get(
            //     `vinculacoes.${index}.vinculacoesModuloLegado.${indexLegado}.senhaUsuarioModulo`
            //   )
            //   .setValue(
            //     this.service.decryptData(
            //       usuario.vinculacoes[index].vinculacoesModuloLegado[
            //         indexLegado
            //       ].senhaUsuarioModulo,
            //       this.secretKey
            //     )
            //   );

            // this.form
            //   .get(
            //     `vinculacoes.${index}.vinculacoesModuloLegado.${indexLegado}.idUnidadeGestora`
            //   )
            //   .setValue(usuario.vinculacoes[index].unidadeGestora.id);

            // this.form
            //   .get(
            //     `vinculacoes.${index}.vinculacoesModuloLegado.${indexLegado}.idModulo`
            //   )
            //   .setValue(
            //     usuario.vinculacoes[index].vinculacoesModuloLegado[indexLegado]
            //       .idModulo
            //   );

            // this.form
            //   .get(
            //     `vinculacoes.${index}.vinculacoesModuloLegado.${indexLegado}.idMunicipio`
            //   )
            //   .setValue(usuario.vinculacoes[index].unidadeGestora.idMunicipio);
          }
        }
        this.form.get(`vinculacoes.${index}.indexVinculacao`).setValue(index);

        for (const modulosLegado of usuario.vinculacoes[index].perfil.perMod) {
          if (
            (modulosLegado.modulo.sistemaLegado === 'S' &&
              usuario.vinculacoes[index].vinculacoesModuloLegado.length ===
                0) ||
            (!usuario.vinculacoes[index].vinculacoesModuloLegado.find(
              (obj) => obj.idModulo === modulosLegado.modulo.id
            ) &&
              modulosLegado.modulo.sistemaLegado === 'S')
          ) {
            const vinculacoesForm: FormArray = this.form.get(
              'vinculacoes'
            ) as FormArray;
            const formGroup = vinculacoesForm.at(Number(index)) as FormGroup;
            const vinculacoesLegadoForm: FormArray = formGroup.get(
              'vinculacoesModuloLegado'
            ) as FormArray;
            vinculacoesLegadoForm.push(
              this.formVincModuloLegado(modulosLegado.modulo.id)
            );
          }
        }

        // this.form
        //   .get(`vinculacoes.${index}.indexVinculacaoLegado`)
        //   .setValue(idxModLegado);

        this.form.get(`vinculacoes.${index}.activeCollapse`).setValue(false);

        this.form
          .get(`vinculacoes.${index}.desUnidadeGestora`)
          .setValue(usuario.vinculacoes[index].desUnidadeGestora);
        this.form
          .get(`vinculacoes.${index}.unidadeGestora`)
          .setValue(usuario.vinculacoes[index].unidadeGestora);
        this.form
          .get(`vinculacoes.${index}.ativo`)
          .setValue(usuario.vinculacoes[index].ativo);
        this.form
          .get(`vinculacoes.${index}.id`)
          .setValue(usuario.vinculacoes[index].id);
        this.form
          .get(`vinculacoes.${index}.validadeAcesso`)
          .setValue(usuario.vinculacoes[index].validadeAcesso);
        this.form
          .get(`vinculacoes.${index}.idHorario`)
          .setValue(usuario.vinculacoes[index].idHorario);
        this.form
          .get(`vinculacoes.${index}.perfil`)
          .setValue(usuario.vinculacoes[index].perfil);
        this.form
          .get(`vinculacoes.${index}.usuarioCriacao`)
          .setValue(usuario.vinculacoes[index].usuarioCriacao);
        this.form
          .get(`vinculacoes.${index}.aplicacaoOrigem`)
          .setValue(usuario.vinculacoes[index].aplicacaoOrigem);
      }
    }
    await this.consultaCpf(usuario.pessoaFisicaCpf);
    this.edit(usuario);
    this.changeTabIndex(0);
  }

  async edit(usuario) {
    usuario.vinculacoes.map(async (vinculacao) => {
      this.perfil = vinculacao.perfil;
      const index = usuario.vinculacoes.indexOf(vinculacao);
      this.loadingPage = true;

      await this.consultaUg(
        vinculacao.unidadeGestora.id,
        index,
        vinculacao.idPerfil
      );
    });
  }

  async consultaUg(idUg, index, idPerfil) {
    clearTimeout(
      this.form.get(`vinculacoes.${index}.intervaloDigitandoUg`).value
    );
    this.form.get(`vinculacoes.${index}.ugNaoEncontrada`).setValue(false);
    this.loadingPage = true;
    this.form.get(`vinculacoes.${index}.intervaloDigitandoUg`).setValue(
      await setTimeout(async () => {
        try {
          let perfis = await this.service.consultaPerfil(idUg, 1, 100);

          this.form.get(`vinculacoes.${index}`).patchValue({
            perfis: perfis,
          });
          this.form.get(`vinculacoes.${index}`).patchValue({ idPerfil });
        } catch (error) {
          this.loadingPage = false;
          this.form.get(`vinculacoes.${index}.ugNaoEncontrada`).setValue(true);
          this.form
            .get(`vinculacoes.${index}.messageUgNaoEncontrada`)
            .setValue(error.message);
          this.form.patchValue({
            idUnidadeGestora: null,
            unidadeGestora: error.data,
            desUnidadeGestora: null,
            idPerfil: null,
            perfis: null,
          });
        }
      }, 500)
    );
    this.loadingPage = false;
  }

  async onSubmit() {
    if (!this.form.valid) {
      this.service.notification.error(
        'Usuário',
        'Preencha todos os campos requeridos!'
      );
      return;
    }

    const vinculacaoSemPerfil =
      this.form.value.vinculacoes.filter((vinculacao) => !vinculacao.idPerfil)
        .length > 0;

    if (vinculacaoSemPerfil) {
      return this.modalService.confirm({
        nzTitle: `<i>Foram encontradas vinculações sem um perfil selecionado, deseja prosseguir mesmo assim?</i>`,
        nzOnOk: async () => {
          await this.executeSubmit();
        },
      });
    }
    await this.executeSubmit();
  }

  async executeSubmit() {
    // if (this.form.value.id && this.form.value.id > 0) {
    //   await this
    //     .update(this.form)
    //     .then((result: any) =>
    //       result.data > 0 ? this.reset() : (this.submitted = false)
    //     );
    //   return;
    // }

    await this.inserirOrUpdateUsuario(this.form);
  }
  async inserirOrUpdateUsuario(usuario) {
    usuario = await this.prepareToRequest(usuario);
    return await this.execute(usuario);
  }

  async prepareToRequest(usuario) {
    usuario = await this.usuarioFormToUsuario(usuario);
    return await usuario;
  }

  async execute(registro, idMp = null, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>${
        registro.id || registro.idPrivado
          ? 'Confirma as alterações a serem enviadas?'
          : 'Confirma os dados a serem cadastrados?'
      }</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        return await this.service
          .insertOrUpdate(registro, idMp, entidade)
          .then((result) => {
            this.reset();
            this.loadingPage = false;
          })
          .catch((err) => {
            this.loadingPage = false;
          });
      },
    });
  }

  async usuarioFormToUsuario(form): Promise<IUsuario | any> {
    form.get('vinculacoes')['controls'].forEach((vinculacao) => {
      if (!vinculacao.value.idPerfil) {
        vinculacao.patchValue({
          idPerfil: { idUnidadeGestora: vinculacao.value.unidadeGestora.id },
        });
      }
    });

    const vinculacoes = await Promise.all(
      (form.get('vinculacoes').value as any[]).map(async (vinculacao) => {
        const vinculacoesModuloLegado = await Promise.all(
          vinculacao.vinculacoesModuloLegado
            .filter((moduloLegado) => moduloLegado.idModulo !== undefined)
            .map(async (moduloLegado) => {
              return {
                apresentarSplash:
                  moduloLegado.splash === null
                    ? 'N'
                    : moduloLegado.splash === true
                    ? 'S'
                    : 'N',
                idModulo: moduloLegado.idModulo,
                nomeUsuarioModulo: moduloLegado.nomeUsuarioModulo ?? null,
                senhaUsuarioModulo: moduloLegado.senhaUsuarioModulo
                  ? await this.service.encryptData(
                      moduloLegado.senhaUsuarioModulo
                    )
                  : null,
                idUnidadeGestora: this.service.authService.getInfoToken()
                  .userInfo.idUg,
                idMunicipio: this.service.authService.getInfoToken().userInfo
                  .idMunicipio,
                idPrivado: moduloLegado.idPrivado
                  ? moduloLegado.idPrivado
                  : undefined,
              };
            })
        );

        return {
          ativo: vinculacao.ativo,
          id: vinculacao.id ? vinculacao.id : undefined,
          validadeAcesso: vinculacao.validadeAcesso,
          idHorario: vinculacao.idHorario?.id,
          perfil: vinculacao.perfis.find(
            (perfil) => perfil.id === vinculacao.idPerfil
          ),
          idPerfil: vinculacao.idPerfil,
          usuarioCriacao: form.get('usuarioCriacao').value,
          aplicacaoOrigem: form.get('aplicacaoOrigem').value,
          vinculacoesModuloLegado,
        };
      })
    );

    return {
      id: form.get('id').value,
      idPessoaFisica: form.get('idPessoaFisica').value,
      senha: form.get('senha').value,
      ativo: form.get('ativo').value ? 1 : 0,
      nomeUsuarioServidor: form.get('nomeUsuarioServidor').value,
      senhaUsuarioServidor: form.get('senhaUsuarioServidor').value
        ? await this.service.encryptData(form.get('senhaUsuarioServidor').value)
        : null,
      usuarioCriacao: form.get('usuarioCriacao').value,
      aplicacaoOrigem: form.get('aplicacaoOrigem').value,
      vinculacoes,
    };
  }

  reset() {
    this.resetForm();
    this.limpaCpf();
    this.resetVinculacao();
  }
  limpaCpf() {
    this.form.patchValue({
      cpfPessoaFisica: null,
      idPessoaFisica: null,
      nomePessoaFisica: null,
      emailPessoaFisica: null,
      ativo: false,
    });
    this.usuarioAtivo = false;
    this.pessoaFisica = null;
  }

  resetVinculacao() {
    const vinculacaoForm: FormArray = this.form.get('vinculacoes') as FormArray;
    vinculacaoForm.controls.splice(0, vinculacaoForm.controls.length);
    this.addVinculacao();
  }

  addVinculacao() {
    const vinculacaoForm: FormArray = this.form.get('vinculacoes') as FormArray;
    vinculacaoForm.push(
      this.formBuilder.group({
        id: [null],
        validadeAcesso: [null, Validators.required],
        idHorario: [null],
        unidadeGestora: [null, Validators.required],
        desUnidadeGestora: [null],
        ugNaoEncontrada: [false],
        intervaloDigitandoUg: [null],
        messageUgNaoEncontrada: [null],
        indexVinculacao: [null],
        indexVinculacaoLegado: [null],
        activeCollapse: [true],
        perfil: [null],
        ativo: [null],
        idPerfil: [null],
        perfis: [null],
        horarios: [null],
        nomeUsuarioModulo: [null],
        senhaUsuarioModulo: [null],
        aplicacaoOrigem: [1],
        usuarioCriacao: [this.authService.getIdUser()],
        vinculacoesModuloLegado: this.formBuilder.array([
          // this.formBuilder.group({
          //   idPrivado: [null],
          //   nomeUsuarioModulo: [null],
          //   senhaUsuarioModulo: [null],
          //   splash: [null],
          //   idUnidadeGestora: [null],
          //   idMunicipio: [null],
          // }),
        ]),
      })
    );
    if (this.tabIndex === 0) {
      this.shouldScrollToLastForm = true;
      this.cdRef.detectChanges();
    }
  }

  check(item) {
    this.checkedAll = this.displayData.every(
      (data: any) => data.checked === true
    );
    this.checkAllIndeterminate = !this.checkedAll;
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  filterUsuario() {
    return this.getUsuarioPerPeriod(this.fieldsUsuariosSelected);
  }

  removeFiltroUsuarioPorField(fieldsValuesTag) {
    const tableExportColumn = this.tableUsuarioColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsUsuariosSelect.splice(
      this.tableUsuarioColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsUsuariosSelected.splice(
      this.fieldsUsuariosSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsUsuariosSelected.length === 0) {
      this.showComboTagsUsuarios = false;
    }
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    if (column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  getHeaderName(vinculacao: any) {
    if (vinculacao.perfis?.length > 0) {
      return vinculacao.desUnidadeGestora;
    }
    return 'Nova Vinculação de Unidade Gestora';
  }

  getHeaderTabName(perfil: any) {
    if (perfil) {
      if (perfil.perMod) {
        return perfil.perMod.filter((key) => key.modulo.sistemaLegado === 'S');
      }
      return perfil.filter((key) => key.modulo.sistemaLegado === 'S');
    }
  }

  selectOption(idPerfil: number, perfil: any, index) {
    this.form.get(`vinculacoes.${index}.indexVinculacao`).setValue(index);

    for (const per of perfil) {
      const perfilLegado = per.perMod?.filter(
        (key) => key.idPerfil === idPerfil && key.modulo.sistemaLegado === 'S'
      );
      if (perfilLegado.length > 0) {
        this.legacyProfile = true;
        this.form.get(`vinculacoes.${index}.perfil`).setValue(perfilLegado);

        if (
          this.form.value.vinculacoes[index].vinculacoesModuloLegado.length ===
          0
        ) {
          const vinculacoesForm: FormArray = this.form.get(
            'vinculacoes'
          ) as FormArray;
          const formGroup = vinculacoesForm.at(Number(index)) as FormGroup;
          const vinculacoesLegadoForm: FormArray = formGroup.get(
            'vinculacoesModuloLegado'
          ) as FormArray;
          for (const modulosLegado of perfilLegado) {
            vinculacoesLegadoForm.push(
              this.formVincModuloLegado(modulosLegado.modulo.id)
            );
          }
        }
      } else {
        this.legacyProfile = false;
      }
    }
  }

  async getUsuarioPerPeriod(fieldsUsuariosSelected) {
    this.loadingPage = true;
    await this.service
      .getAllRegistrosInInterval({
        params: fieldsUsuariosSelected,
        entidade: this.entidade,
        titulo: this.titulo,
      })
      .then(async (example: any) => {
        if (example.data.length === 0) {
          this.service.notification.warning(
            this.titulo,
            'Nenhum registro encontrado.',
            { nzDuration: 7000 }
          );
          this.loadingPage = false;

          return;
        }
        this.pageTotal = example.data.paramsPaginator[0];
        this.displayData = example.data.resultado;

        this.loadingPage = false;
      })
      .catch((err) => {
        this.service.notification.error(this.titulo, err);
        this.loadingPage = false;
      });
  }

  addFiltroValuesPorCampo() {
    if (this.fieldUsuariosSelected && this.searchInput) {
      if (this.fieldUsuariosSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldUsuariosSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      if (this.fieldsUsuariosSelected.length !== 0) {
        this.removeFiltroUsuarioPorField(this.fieldsUsuariosSelected[0]);
      }
      this.fieldsUsuariosSelected.push({
        props: this.fieldUsuariosSelected.value,
        field: this.fieldUsuariosSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldUsuariosSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsUsuariosSelect.findIndex(
        (a) => a.value === this.fieldUsuariosSelected.value
      );

      this.arrColumnsUsuariosSelect.splice(index, 1);

      this.showComboTagsUsuarios = true;
      this.fieldUsuariosSelected = '';
      this.searchInput = '';
      this.selectedUsuariosValues = [];
    }
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;
    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  verificaVinculacaoVazia(vinculacoes): boolean {
    return (
      vinculacoes.filter((vinculacao) => vinculacao.unidadeGestora).length > 0
    );
  }
}
