<nz-spin [nzSpinning]="loadingPage" nzSize="large">
  <ps-complex-form [title]="titulo" [tabIndex]="tabIndex" [isAntd]="true" (changeTabIndex)="changeTabIndex($event)">
    <div body>
      <nz-spin [nzSpinning]="loadingManutencao">
        <form nz-form [formGroup]="form" nzLayout="vertical">
          <h4 class="m-t-15 m-l-20">Informações básicas</h4>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <div nz-row class="p-l-20 p-r-20" nzGutter="18">
            <div nz-col nzSpan="8" nzSm="8" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Unidade gestora</nz-form-label>
                <nz-form-control [nzValidateStatus]="'success'" nzErrorTip="Código da unidade gestora é obrigatório.">
                  <nz-input-group nzSearch [nzAddOnAfter]="showModalUgReference">
                    <input formControlName="desUnidadeGestora" (keydown)="showModalUg()" nz-input
                      placeholder="Código da Unidade gestora" (click)="showModalUg()" />
                  </nz-input-group>
                </nz-form-control>
                <ng-template #showModalUgReference>
                  <button *ngIf="unidadeGestora" (click)="reset()" nz-button type="button" nzType="text" nzSearch>
                    <i nz-icon nzType="close-circle"></i>
                  </button>
                  <button nz-button nzType="primary" (click)="showModalUg()" nzSearch><span nz-icon
                      nzType="search"></span></button>
                </ng-template>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="7" nzSm="7" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Número</nz-form-label>
                <nz-form-control [nzValidateStatus]="getValidateStatus('numero')" nzErrorTip="Número é obrigatório.">
                  <input nz-input formControlName="numero" placeholder="Número" [mask]="'9{20}'">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="6" nzSm="6" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Validade</nz-form-label>
                <nz-form-control
                  *ngIf="form.value.validade === 0 && form.value.validade !== '' && form.value.validade !== null"
                  [nzValidateStatus]="getValidateStatus('validade')" nzErrorTip="Poder é obrigatória.">
                  <nz-select [(ngModel)]="ngModelValidade" fullWidth nzShowSearch formControlName="validade"
                    nzPlaceHolder="Validade acesso">
                    <nz-option [nzValue]=0 [nzLabel]="'Indeterminado'">
                    </nz-option>
                    <nz-option [nzValue]=null [nzLabel]="'Personalizado'">
                    </nz-option>
                  </nz-select>
                </nz-form-control>

                <nz-form-control
                  *ngIf="form.value.validade !== 0 || form.value.validade === '' || form.value.validade === null"
                  [nzValidateStatus]="getValidateStatus('validade')" nzErrorTip="validade é obrigatória.">
                  <input nz-input formControlName="validade" (focusout)="checkTipoValidade()"
                    placeholder="Digite um valor em dias" [mask]="'9{4}'">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="3" nzSm="3" nzXs="24">
              <nz-form-label></nz-form-label>
              <label nz-checkbox class="checkbox-col" formControlName="situacao"
                [(ngModel)]="checkboxAtivo">Ativo</label>
            </div>
          </div>
        </form>
        <div>

          <nz-tabset nzType="card">
            <span nz-icon [nzType]="'lock'"></span>
            <nz-tab *ngFor="let modulo of modulos" nz-icon [nzType]="'lock'" [nzTitle]="titleTemplate">
              <ng-template #titleTemplate>
                <!-- <span nz-icon [nzType]="modulo.data.checked?'unlock':'lock'"></span> -->

                {{modulo.data.name}}
              </ng-template>
              <nz-collapse nzAccordion>
                <nz-collapse-panel [nzHeader]="'Submodulos'+' '+ modulo.data.name" [nzActive]="true">

                  <div nz-row [nzGutter]="[32,32]" class="p-l-10 p-r-10">

                    <!-- <div nz-row [nzGutter]="[32,32]"> -->
                    <div nz-col [nzSpan]="24" [nzSm]="24" [nzXs]="24">

                      <div nz-row [nzGutter]="[12,12]" class="p-l-10 p-r-10">
                        <!-- <label nz-checkbox (ngModelChange)="checkAllLicenca(submodulo.children)"
                          [(ngModel)]="submodulo.checkedAll">Selecionar Todos</label> -->
                        <ng-template ngFor let-submodulo [ngForOf]="modulo.children" let-index="index">

                          <div nz-col [nzSpan]="submodulo.data.name.leng<=5?4:5"
                            [nzSm]="submodulo.data.name.length<=5?4:5" [nzXs]="24">
                            <label nz-checkbox (ngModelChange)="checkLicense(submodulo)"
                              [(ngModel)]="submodulo.data.checked">{{submodulo.data.name}}</label>
                          </div>

                        </ng-template>
                      </div>

                    </div>


                  </div>

                </nz-collapse-panel>

              </nz-collapse>
              <nz-collapse>
                <nz-collapse-panel [nzHeader]="'Formulários'" [nzActive]="hasSubmoduleChecked(modulo)">
                  <div nz-row [nzGutter]="[32,32]" class="p-l-10 p-r-10">

                    <ng-template ngFor let-submodulo [ngForOf]="modulo.children" let-index="index">
                      <div nz-col *ngIf="submodulo.data.checked" [nzSpan]="8" [nzSm]="8" [nzXs]="24">
                        <nz-collapse nzGhost>
                          <nz-collapse-panel [nzHeader]="'Formulário'+' '+ submodulo.data.name"
                            [nzActive]="submodulo.data.checked">
                            <!-- <label nz-checkbox (ngModelChange)="checkAllLicenca(modulo.children)"
                              [(ngModel)]="modulo.checkedAll">Selecionar Todos</label> -->
                            <ng-template ngFor let-formulario [ngForOf]="submodulo.children" let-index="index">

                              <div nz-col [nzSpan]="24" [nzSm]="24" [nzXs]="24">
                                <label nz-checkbox (ngModelChange)="checkLicense(formulario)"
                                  [(ngModel)]="formulario.data.checked">{{formulario.data.name}}</label>
                              </div>
                            </ng-template>
                          </nz-collapse-panel>

                        </nz-collapse>
                      </div>
                    </ng-template>

                  </div>
                </nz-collapse-panel>
              </nz-collapse>


            </nz-tab>
          </nz-tabset>



        </div>
      </nz-spin>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
    <div table>
      <div class="row is-not-mobile">
        <div class="col-md-24 col-xl-12 p-b-15">
          <nz-collapse nzGhost>
            <nz-collapse-panel [nzHeader]="'Filtros'" [nzActive]="true">
              <div nz-row class="d-flex">
                <div nz-col nzSpan="24" nzXs="24">
                  <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                    <div class="p-r-10">
                      <div class="p-l-10" nz-col nzSpan="24">
                        <div nz-row nzGutter="16">
                          <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                            <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo"
                              [(ngModel)]="fieldLicencasSelected"
                              (ngModelChange)="getFilterLicencaOptionsSelect(fieldLicencasSelected)">
                              <nz-option *ngFor="let interval of arrColumnsLicencasSelect" [nzValue]="interval"
                                [nzLabel]="interval?.text">
                              </nz-option>
                            </nz-select>
                          </div>
                          <div nz-col nzSpan="8" [nzOffset]="this.openModal?2:0" nzXl="8" nzLg="12" nzXs="24">
                            <nz-input-group *ngIf="fieldLicencasSelected && fieldLicencasSelected.type === 'input'">
                              <input type="text" nz-input class="text-disabled" placeholder="Informe um valor"
                                [(ngModel)]="searchInput" />
                            </nz-input-group>
                          </div>
                          <div
                            *ngIf="fieldLicencasSelected && tabIndex == 1 && fieldLicencasSelected.type === 'range-picker'"
                            nzSpan="8" nzXl="8" nzLg="8" nzXs="24" class="m-l-10">
                            <nz-range-picker nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-range-picker>
                          </div>
                          <div nz-col *ngIf="fieldLicencasSelected" nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
                            <button nz-button [disabled]="
                                !searchInput
                              " (click)="addFiltroValuesPorCampo()" nzType="primary">
                              <i nz-icon nzType="plus"></i>
                            </button>
                          </div>

                        </div>
                        <div nz-row class="m-t-10">
                          <div *ngIf="showComboTagsLicencas" class="p-0" nz-col nzSpan="12" nzXl="12" nzLg="12"
                            nzXs="24">
                            <nz-input-group [nzPrefix]="' '">
                              <div class="align-self-center">
                                <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'" (nzOnClose)="
                            removeFiltroLicencaPorField(fieldsValuesTag)
                            " class="filters" *ngFor="let fieldsValuesTag of fieldsLicencasSelected; let i = index ">
                                  {{ fieldsValuesTag.text }}
                                </nz-tag>
                              </div>
                            </nz-input-group>
                          </div>
                          <div nz-col [class]="'m-t-10 p-l-20'"
                            [nzOffset]="fieldsLicencasSelected.length !=0?openModal?7:8:openModal?19:20" nzSpan="3"
                            nzXl="3" nzLg="3" nzXs="24">

                            <button class="m-l-10" nzSize="small" nz-button nzType="primary" (click)="filterLicenca()">
                              <i nz-icon nzType="filter" nzTheme="outline"></i>Aplicar Filtro
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
      <nz-table nzSize="small" [nzLoading]="loadingTable" [nzShowPagination]="false" #LicencaListTable
        [nzScroll]="{ y: '420px' }" style="overflow-y: overlay" [nzData]="displayData">
        <thead>
          <tr>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " [nzLeft]="true" nzWidth="15px"></th>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " nzWidth="60px" [nzLeft]="true"><label nz-checkbox
                [nzIndeterminate]="checkAllIndeterminate" (ngModelChange)="updateAllChecked()"
                [(ngModel)]="checkedAll"></label>
            </th>
            <ng-container *ngFor="let column of tableLicencaColumns">
              <th class="p-t-15 p-b-15" nzWidth="100px" *ngIf="checkList[column.value]" nzWidth="{{column.width}}"
                [nzSortFn]="column.compare">
                {{ column.text }} </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-item [ngForOf]="LicencaListTable.data" let-index="index">
            <tr (click)="selectItem(item)" (dblclick)="dbclick(item)" style="cursor: pointer" nzTooltipPlacement="left">
              <td [nzLeft]="getWidthContent() > 1024"></td>
              <td [nzLeft]="getWidthContent() > 1024">
                <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="check(item)"></label>
              </td>
              <ng-container *ngFor="let column of tableLicencaColumns">
                <td *ngIf="checkList[column.value]">
                  {{ prepareColumnValue(item, column) }}
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>
      <nz-pagination style="text-align: end" class="m-t-20" [nzSize]="'small'" [nzPageIndex]="1" [nzTotal]="pageTotal"
        (nzPageIndexChange)="pageChange($event)"></nz-pagination>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
  </ps-complex-form>
</nz-spin>